import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { StoreModule } from "@ngrx/store";
import { ChartsModule, ThemeService } from "ng2-charts";
import { SharedModule } from "shared/shared.module";

import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routes";
import { ChartaccountComponent } from "./pages/accounting/chartaccount/chartaccount.component";
import { CreateAccountComponent } from "./pages/accounting/chartaccount/create-account/create-account.component";
import { TemplateBrowserComponent } from "./pages/accounting/chartaccount/template-browser/template-browser.component";
import { JournalEntryComponent } from "./pages/accounting/journaltransactions/journal-entry/journal-entry.component";
import { JournaltransactionsComponent } from "./pages/accounting/journaltransactions/journaltransactions.component";
import { AddDepositComponent } from "./pages/accounting/transactions/add-deposit/add-deposit.component";
import { AddObligationComponent } from "./pages/accounting/transactions/add-obligation/add-obligation.component";
import { CurrencyExchangeComponent } from "./pages/accounting/transactions/currency-exchange/currency-exchange.component";
import { FundsTransferComponent } from "./pages/accounting/transactions/funds-transfer/funds-transfer.component";
import { TransactionsComponent } from "./pages/accounting/transactions/transactions.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { MujtahidsAddComponent } from "./pages/obligations/mujtahids/mujtahids-add/mujtahids-add.component";
import { MujtahidsListComponent } from "./pages/obligations/mujtahids/mujtahids-list/mujtahids-list.component";
import { ObligationListComponent } from "./pages/obligations/obligation-list/obligation-list.component";
import { ObligationTypeAddComponent } from "./pages/obligations/obligation-types/obligation-type-add/obligation-type-add.component";
import { ObligationTypeListComponent } from "./pages/obligations/obligation-types/obligation-type-list/obligation-type-list.component";
import { RepresentativesAddComponent } from "./pages/obligations/representatives/representatives-add/representatives-add.component";
import { RepresentativesListComponent } from "./pages/obligations/representatives/representatives-list/representatives-list.component";
import { ProfileAddComponent } from "./pages/profiles/profile-add/profile-add.component";
import { ProfileListComponent } from "./pages/profiles/profile-list/profile-list.component";
import { AccountTransactionsComponent } from "./pages/reports/account-transactions/account-transactions.component";
import { BalanceSheetComponent } from "./pages/reports/balance-sheet/balance-sheet.component";
import { GeneralLedgerComponent } from "./pages/reports/general-ledger/general-ledger.component";
import { IncomeStatementComponent } from "./pages/reports/income-statement/income-statement.component";
import { ObligationReportComponent } from "./pages/reports/obligation-report/obligation-report.component";
import { TrialBalanceComponent } from "./pages/reports/trial-balance/trial-balance.component";
import { BusinessesComponent as DefaultBusinessesComponent } from "./pages/settings/account/businesses/businesses.component";
import { ChangepasswordComponent } from "./pages/settings/account/changepassword/changepassword.component";
import { ProfileComponent } from "./pages/settings/account/profile/profile.component";
import { BusinessesComponent } from "./pages/settings/advancedsettings/businesses/businesses.component";
import { UsersComponent } from "./pages/settings/advancedsettings/users/users.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { reducer } from "./state/app.reducer";
import { UserModule } from "./user/user.module";
import { UserAddComponent } from "./pages/settings/advancedsettings/users/user-add/user-add.component";
import { BusinessAddComponent } from "./pages/settings/advancedsettings/businesses/business-add/business-add.component";
import { ObligationReceiptComponent } from "./shared/components/obligation-receipt/obligation-receipt.component";
import { Overlay, OverlayContainer, ToastrService } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MultiSelectModule } from 'primeng/multiselect';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TransactionsComponent,
    ChartaccountComponent,
    JournaltransactionsComponent,
    ProfileComponent,
    ChangepasswordComponent,
    UsersComponent,
    BusinessesComponent,
    DefaultBusinessesComponent,
    SettingsComponent,
    BalanceSheetComponent,
    IncomeStatementComponent,
    GeneralLedgerComponent,
    AccountTransactionsComponent,
    TrialBalanceComponent,
    ObligationReportComponent,
    CreateAccountComponent,
    TemplateBrowserComponent,
    JournalEntryComponent,
    AddDepositComponent,
    AddObligationComponent,
    ObligationTypeListComponent,
    ObligationTypeAddComponent,
    FundsTransferComponent,
    CurrencyExchangeComponent,
    ProfileListComponent,
    ProfileAddComponent,
    ObligationListComponent,
    MujtahidsListComponent,
    MujtahidsAddComponent,
    RepresentativesListComponent,
    RepresentativesAddComponent,
    UserAddComponent,
    BusinessAddComponent,
    ObligationReceiptComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    SharedModule,
    UserModule,
    ChartsModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    StoreModule.forRoot({ app: reducer }),
    MultiSelectModule
  ],
  providers: [
    // {
    //   provide: UrlSerializer,
    //   useClass: LowerCaseUrlSerializer,
    // },
    NgbActiveModal,
    ToastrService,
    Overlay,
    OverlayContainer,
    ChartsModule,
    ThemeService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CreateAccountComponent,
    TemplateBrowserComponent,
    AddDepositComponent,
  ],
})
export class AppModule { }
