export function primaryNav(){
  (function () {
    "use strict";
  
    var primaryNavClass = "wv-nav--primary",
      menuItemClass = "wv-nav__menu__item",
      expandableMenuItemClass = "wv-nav__menu__item--expandable",
      openClass = "is-open",
      currentClass = "is-current",
      primaryNavSelector = "." + primaryNavClass,
      menuItemSelector = "." + menuItemClass,
      menuItems,
      i;
  
    function isModifiedClickEvent(event) {
      return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
    }
  
    function closestMenuItemParent(el) {
      do {
        el = el.parentElement;
        if (el.classList.contains(primaryNavClass)) {
          return null;
        }
      } while (!el.classList.contains(menuItemClass));
  
      return el;
    }
  
    function toggleOpen(menuItem) {
      var j;
  
      if (menuItem.classList.contains(openClass) && !(menuItem.classList.contains(currentClass))) {
        menuItem.classList.remove(openClass);
      } else {
        for (j = 0; j < menuItems.length; j++) {
          if (!menuItems[j].classList.contains(currentClass)) {
            menuItems[j].classList.remove(openClass);
          }
        }
        menuItem.classList.add(openClass);
      }
    }
  
    function toggleCurrent(menuItem) {
      var k;
  
      for (k = 0; k < menuItems.length; k++) {
        menuItems[k].classList.remove(currentClass);
        menuItems[k].classList.remove(openClass);
      }
      menuItem.classList.add(currentClass);
      var closestParent = closestMenuItemParent(menuItem);
      if (!closestParent) {
        return;
      }
      closestParent.classList.add(currentClass);
      closestParent.classList.add(openClass);
    }
  
    function menuItemClickHandler(ev) {
      if (isModifiedClickEvent(ev)) {
        return;
      }
      var menuItem = closestMenuItemParent(ev.target);
  
      if (menuItem.classList.contains(expandableMenuItemClass)) {
        toggleOpen(menuItem);
      } else {
        toggleCurrent(menuItem);
      }
    }
    menuItems = document.querySelector(primaryNavSelector).querySelectorAll(menuItemSelector);
    for (i = 0; i < menuItems.length; i++) {
      menuItems[i].addEventListener("click", menuItemClickHandler);
    }
  }());
  
  (function () {
    "use strict";
  
    var specs = [{
        component: ".wv-dropdown",
        triggers: [".wv-dropdown__toggle",
          ".wv-dropdown__menu__link"
        ]
      },
      {
        component: ".wv-frame",
        triggers: [".wv-topbar__menu-toggle"],
        preventScroll: true
      },
      {
        component: ".wv-datepicker",
        triggers: [".wv-datepicker__toggle",
          ".wv-datepicker__day"
        ]
      },
      {
        component: ".wv-datepicker--range",
        triggers: [".wv-datepicker__toggle",
          ".wv-datepicker__day"
        ]
      },
      {
        component: ".wv-select",
        triggers: [".wv-select:not(.is-disabled) .wv-select__toggle",
          ".wv-select__menu__option"
        ]
      },
      {
        component: "[class*='wv-popover']:not(.is-persistent)",
        triggers: [".wv-popover__toggle",
          ".wv-popover__panel .wv-close"
        ]
      },
      {
        component: "[class*='wv-popover'].is-persistent",
        triggers: [".wv-popover__toggle",
          ".wv-popover__panel .wv-close"
        ],
        persist: true
      },
      {
        component: ".docs-markup",
        triggers: [".docs-markup__open-toggle"],
        persist: true
      },
      {
        component: ".wv-business-switcher",
        triggers: [".wv-business-switcher__toggle",
          ".wv-business-switcher__panel__header__close"
        ]
      }
    ];
  
    var toggleable = (function () {
      var openClass = "is-open";
      var preventScrollClass = "prevent-scroll";
  
      function open() {
        this.element.classList.add(openClass);
  
        if (this.preventScroll) {
          document.body.classList.toggle(preventScrollClass);
        }
  
        if (!this.persist) {
          setTimeout(function () {
            document.addEventListener("click", function clickAnywhereHandler(ev) {
              if (!this.element.contains(ev.target)) {
                this.close();
                document.removeEventListener("click", clickAnywhereHandler);
              }
            }.bind(this));
            // Allow original trigger click to propagate through before adding
            // the click listener on the document.
          }.bind(this), 100);
        }
        this.isOpen = true;
      }
  
      function close() {
        this.element.classList.remove(openClass);
  
        if (this.isOpen && this.preventScroll) {
          document.body.classList.toggle(preventScrollClass);
        }
  
        this.isOpen = false;
      }
  
      function toggle() {
        if (this.isOpen) {
          this.close();
        } else {
          this.open();
        }
      }
  
      function init(element, triggers, persist, preventScroll) {
        this.element = element;
        this.triggers = triggers;
        this.persist = persist || false;
        this.preventScroll = preventScroll || false;
        this.isOpen = element.classList.contains(openClass) ? true : false;
  
        this.close();
  
        this.triggers.forEach(function (trigger) {
          trigger.addEventListener("click", toggle.bind(this));
        }, this);
  
        return this;
      }
  
      return {
        open: open,
        close: close,
        toggle: toggle,
        init: init
      };
    }());
  
    var setupToggleables = function (specs) {
      var components,
        triggers,
        triggerNodes,
        triggerArray,
        toggleables = [],
        i, j;
  
      specs.forEach(function (spec) {
        components = document.querySelectorAll(spec.component);
        for (i = 0; i < components.length; i++) {
          triggers = [];
          for (j = 0; j < spec.triggers.length; j++) {
            triggerNodes = components[i].querySelectorAll(spec.triggers[j]);
            triggerArray = Array.prototype.slice.call(triggerNodes);
            triggers = triggers.concat(triggerArray);
          }
          toggleables.push(Object.create(toggleable).init(components[i], triggers, spec.persist, spec.preventScroll));
        }
      });
  
      return toggleables;
    };
  
    var setupButtonGroups = function () {
      var groups, children, i;
  
      groups = document.querySelectorAll(".js-wv-button-group");
      groups.forEach(function (group) {
        group.onclick = function (e) {
          children = this.children;
          for (i = 0; i < children.length; i++) {
            children[i].classList.remove("is-active");
          }
          e.target.classList.add("is-active");
        };
      });
    };
  
  
    window.toggleables = setupToggleables(specs);
    window.buttonGroups = setupButtonGroups();
  
  }());
  
}