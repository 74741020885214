import { ToastrService } from "ngx-toastr";
import { CreateAccountComponent } from "./create-account/create-account.component";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TemplateBrowserComponent } from "./template-browser/template-browser.component";

@Component({
  selector: "app-chartaccount",
  templateUrl: "./chartaccount.component.html",
  styleUrls: ["./chartaccount.component.css"],
})
export class ChartaccountComponent implements OnInit {
  accounts = [];
  templates: any;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private toastrService: ToastrService
  ) {
    this.getChartAccounts();
  }

  ngOnInit() {}

  delete(account) {
    if (confirm("Are you sure you want to delete this account?")) {
      this.http.delete(`api/chartaccounts/${account.id}`).subscribe(
        (response) => {
          this.accounts.find(
            (x) => x.groupName === account.accountType
          ).accounts = this.accounts
            .find((x) => x.groupName === account.accountType)
            .accounts.filter((x) => x.id !== account.id);

          this.toastrService.success(
            "Account deleted successfully!",
            "SUCCESS"
          );
        },
        (error) => {
          this.toastrService.error(error.error.message, "ERROR");
        }
      );
    }
  }

  getChartAccounts() {
    this.http.get("api/chartaccounts").subscribe((response) => {
      this.accounts = this.groupByChartAccounts(response, "accountType");
      this.accounts.sort((prev, next) => {
        if (prev.groupName === "Assets") {
          return -1;
        } else if (prev.groupName === "Liabilities") {
          return 0;
        } else {
          return 1;
        }
      });
    });
  }

  addNewChartAccountToList(value) {
    this.accounts
      .find((x) => x.groupName === value.type)
      .accounts.push(value.account);
  }

  openTemplateBrowser() {
    if (this.templates) {
      const modalRef = this.modalService.open(TemplateBrowserComponent, {
        backdropClass: "in",
        windowClass: "in",
      });
      modalRef.componentInstance.templates = this.templates;
      modalRef.componentInstance.accountAdded.subscribe((value) =>
        this.addNewChartAccountToList(value)
      );
    } else {
      this.http.get("api/chartaccounts/templates").subscribe((response) => {
        this.templates = this.groupByTemplates(response);

        const modalRef = this.modalService.open(TemplateBrowserComponent, {
          backdropClass: "in",
          windowClass: "in",
        });
        modalRef.componentInstance.templates = this.templates;
        modalRef.componentInstance.accountAdded.subscribe((value) =>
          this.addNewChartAccountToList(value)
        );
      });
    }
  }

  open(account) {
    const modalRef = this.modalService.open(CreateAccountComponent, {
      backdropClass: "in",
      windowClass: "in",
    });
    modalRef.componentInstance.account = account;
  }

  groupByChartAccounts(OurArray, property) {
    return OurArray.reduce(function (accumulator, object) {
      // get the value of our object(age in our case) to use for group    the array as the array key
      const key = object[property];
      // if the current value is similar to the key(age) don't accumulate the transformed array and leave it empty
      if (!accumulator.find((m) => m.groupName === key)) {
        accumulator.push({ groupName: key, accounts: [] });
      }
      // add the value to the array
      accumulator.find((m) => m.groupName === key).accounts.push(object);
      // return the transformed array
      return accumulator;
      // Also we also set the initial value of reduce() to an empty object
    }, []);
  }

  groupByTemplates(OurArray) {
    return OurArray.reduce(function (accumulator, object) {
      // get the value of our object(age in our case) to use for group    the array as the array key
      const type = object["type"];
      const accountClass = object["class"];
      const group = object["group"];

      if (!accumulator.find((m) => m.name === type)) {
        accumulator.push({ name: type, classes: [] });
      }

      if (
        !accumulator
          .find((m) => m.name === type)
          .classes.find((m) => m.name === accountClass)
      ) {
        accumulator
          .find((m) => m.name === type)
          .classes.push({ name: accountClass, groups: [] });
      }

      if (
        !accumulator
          .find((m) => m.name === type)
          .classes.find((m) => m.name === accountClass)
          .groups.find((m) => m.name === group)
      ) {
        accumulator
          .find((m) => m.name === type)
          .classes.find((m) => m.name === accountClass)
          .groups.push({ name: group, accounts: [] });
      }

      accumulator
        .find((m) => m.name === type)
        .classes.find((m) => m.name === accountClass)
        .groups.find((m) => m.name === group)
        .accounts.push(object);

      // return the transformed array
      return accumulator;

      // Also we also set the initial value of reduce() to an empty object
    }, []);
  }

  groupBy(OurArray, property) {
    return OurArray.reduce(function (accumulator, object) {
      // get the value of our object(age in our case) to use for group    the array as the array key
      const key = object[property];

      // if the current value is similar to the key(age) don't accumulate the transformed array and leave it empty
      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      // add the value to the array
      accumulator[key].push(object);

      // return the transformed array
      return accumulator;

      // Also we also set the initial value of reduce() to an empty object
    }, {});
  }

  toggleAccountStatus(account) {
    this.http.put("api/chartaccounts/" + account.id, account).subscribe(
      (res) =>
        this.toastrService.info("Account updated successfully!", "SUCCESS"),
      (error) => this.toastrService.error(error.error.message, "ERROR")
    );
  }
}
