import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NgForm } from "@angular/forms";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  error = false;
  errorMessage = null;
  loading = false;
  code: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((response) => {
      if (!response.code) {
        router.navigate(["login"]);
        return;
      }

      this.code = response.code;
    });
  }

  ngOnInit() {}

  submitForm(form: NgForm) {
    if (form.value.NewPassword !== form.value.ConfirmPassword) {
      return;
    }

    this.loading = true;
    const requestBody: any = { ...form.value };
    requestBody.code = this.code;

    this.http.post("api/auth/reset-password", requestBody).subscribe(
      (response) => {
        this.router.navigate(["login"], {
          state: { showPasswordResetSuccess: true },
        });
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.error = true;
        this.errorMessage = error.message;
        form.resetForm();
        form.reset();
      },
      () => {
        this.loading = false;
        form.resetForm();
        form.reset();
      }
    );
  }
}
