import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-businesses",
  templateUrl: "./businesses.component.html",
  styleUrls: ["./businesses.component.css"],
})
export class BusinessesComponent implements OnInit {
  private readonly baseRoute = "api/businesses";

  loadingBusinesses = false;

  businesses;

  constructor(private http: HttpClient) {
    this.loadAllBusinesses();
  }

  ngOnInit() {}

  loadAllBusinesses() {
    this.loadingBusinesses = true;
    this.http.get(this.baseRoute).subscribe(
      (response) => (this.businesses = response),
      () => {},
      () => (this.loadingBusinesses = false)
    );
  }
}
