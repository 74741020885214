import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-create-account",
  templateUrl: "./create-account.component.html",
  styleUrls: ["./create-account.component.css"],
})
export class CreateAccountComponent implements OnInit {
  @Input() public account;
  @Input() public isNewAccount = false;
  @Output() accountCreated: EventEmitter<any> = new EventEmitter();

  currencyListOptions: object;
  model: any;

  constructor(
    private http: HttpClient,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService
  ) {
    http.get("api/currencies").subscribe((response) => {
      this.currencyListOptions = response;
    });
  }

  ngOnInit() {
    this.model = { ...this.account, isActive: true };

    if (this.isNewAccount) {
      delete this.model.id;
      this.model.templateId = this.account.id;
    }
  }

  save() {
    if (this.isNewAccount) {
      this.http.post(`api/chartaccounts`, this.model).subscribe(
        (response) => {
          this.accountCreated.emit(response);
          this.activeModal.close("Cross click");

          this.toastrService.success("Account added successfully!", "SUCCESS");
        },
        (error) => this.toastrService.error(error.error.message, "ERROR")
      );
    } else {
      this.http.put(`api/chartaccounts/${this.model.id}`, this.model).subscribe(
        () => {
          this.account.name = this.model.name;
          this.account.isPaymentAccount = this.model.isPaymentAccount;
          this.account.currencyId = this.model.currencyId;
          this.activeModal.close("Cross click");

          this.toastrService.success(
            "Account updated successfully!",
            "SUCCESS"
          );
        },
        (error) => this.toastrService.error(error.error.message, "ERROR")
      );
    }
  }

  close() {
    this.activeModal.close("Cross click");
  }
}
