import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ChartAccountService } from "src/app/services/chart-account.service";

@Component({
  selector: "app-account-select",
  templateUrl: "./account-select.component.html",
  styleUrls: ["./account-select.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountSelectComponent),
      multi: true,
    },
  ],
})
export class AccountSelectComponent implements OnInit, ControlValueAccessor {
  @Input() excludePaymentAccounts = false;
  @Input() showAddOption = true;
  @Input() caption = null;

  @Input() value: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();

  accounts: any[] = [];
  disabled = false;

  constructor(private chartAccountService: ChartAccountService) {}

  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(newValue) {
    this.valueChange.emit(newValue);
    this.onModelChange(newValue);
  }

  ngOnInit() {
    this.chartAccountService.accounts$.subscribe((accounts) => {
      this.accounts = this.excludePaymentAccounts
        ? accounts.filter((x) => !x.isPaymentAccount)
        : accounts;
    });
  }
}
