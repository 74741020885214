import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  forwardRef,
  ElementRef,
  ViewChildren,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-person-select",
  templateUrl: "./person-select.component.html",
  styleUrls: ["./person-select.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonSelectComponent),
      multi: true,
    },
  ],
})
export class PersonSelectComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  loading = false;
  @Input() showAddOption = false;
  @Input() caption;
  @Input() value;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  keywords = "";
  hidden = true;
  showSuggestions = false;
  suggestions;
  disabled = false;

  constructor(private httpClient: HttpClient) {
    this.httpClient
      .get(`api/profiles/search?keywords=${this.keywords}`)
      .subscribe((response) => {
        this.suggestions = response;
      });
  }

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
      this.loadSinglePerson();
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    
  }

  loadSinglePerson() {
    if (this.value) {
      this.loading = true;
      this.httpClient.get(`api/profiles/search?id=${this.value}`).subscribe(
        (response) => {
          if (response[0]) {
            this.keywords = response[0].fullName;
          }
        },
        () => {},
        () => (this.loading = false)
      );
    }
  }

  ngOnChanges(event) {
    if (!event.value.previousValue && event.value.currentValue) {
      this.loadSinglePerson();
    }
  }

  selectPerson(value) {
    this.valueChange.emit(value.id);
    this.onModelChange(value.id);

    this.hidden = true;
    this.showSuggestions = false;
    this.keywords = value.fullName;

    // this.value = value.id;
    // this.valueChange.emit(this.value);
  }

  keyDownHandle() {
    this.valueChange.emit(undefined);
    this.onModelChange(undefined);
  }

  getSuggestions() {
    if (this.keywords.length < 4) {
      return;
    }

    this.loading = true;
    this.httpClient
      .get(`api/profiles/search?keywords=${this.keywords}`)
      .subscribe(
        (response) => {
          this.suggestions = response;
        },
        () => {},
        () => (this.loading = false)
      );
  }

  visibleSuggestions() {
    this.hidden = false;
    this.showSuggestions = true;
  }

  clearSelection() {
    this.valueChange.emit(undefined);
    this.onModelChange(undefined);
    this.keywords = "";
  }

  hideSuggestions() {
    this.hidden = true;
    setTimeout(
      function () {
        this.showSuggestions = false;
      }.bind(this),
      200
    );
  }
}
