import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import COOKIEKEYS from "auth/constants/cookieKeys.constants";
import LOCALSTORAGEKEYS from "auth/constants/localStorageKeys.constants";
import { CookieService } from "ngx-cookie-service";

import { User } from "../../user/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser: User;
  redirectUrl: string;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private router: Router
  ) { }

  get isLoggedIn(): boolean {
    //const aspNetIdentityCookie = this.cookieService.get(COOKIEKEYS.APPCOOKIE);
    const businessId = window.localStorage.getItem(LOCALSTORAGEKEYS.BUSINESSID);
    const token = window.localStorage.getItem("token");

    return !!token && !!businessId;
  }

  login(userName: string, password: string, errorCallback): void {
    if (!userName || !password) {
      return;
    }

    const payload = {
      userName,
      password,
    };

    this.http
      .post<User>("api/auth/login", payload)
      .subscribe(this.handleLoginSuccess.bind(this), errorCallback);
  }

  getUser() {
    return JSON.parse(window.localStorage.getItem(LOCALSTORAGEKEYS.USER));
  }


  getRoles() {
    try {
      return JSON.parse(window.localStorage.getItem(LOCALSTORAGEKEYS.USER)).roles;
    } catch {
      return [];
    }
  }

  isAdmin() {
    return this.getRoles().includes("Admin");
  }

  getBusiness() {
    const user = JSON.parse(window.localStorage.getItem(LOCALSTORAGEKEYS.USER));
    const businessId = parseInt(
      window.localStorage.getItem(LOCALSTORAGEKEYS.BUSINESSID)
    );

    const business = user.businesses.find((b) => b.id === businessId);

    if (!business) {
      this.logout();

      return;
    }

    return business;
  }

  getAllBusinesses() {
    const user = JSON.parse(window.localStorage.getItem(LOCALSTORAGEKEYS.USER));
    return user.businesses;
  }

  switchBusiness(businessIdToSwitch: number) {
    if (businessIdToSwitch === this.getBusiness().id) {
      return;
    }

    this.setBusinessId(businessIdToSwitch);
    localStorage.removeItem("transactionFilters");

    // this.router.navigate(["dashboard"]);
    window.location.href = "dashboard";
  }

  resetDefaultBusinessId(businessId: number) {
    const user = this.getUser();

    if (user.defaultBusinessId !== businessId) {
      user.defaultBusinessId = businessId;
      this.setUser(user);
    }
  }

  logout(): void {
    this.cookieService.deleteAll();
      window.localStorage.clear();

      this.router.navigate(["login"]);
    // this.http.post("api/auth/logout", {}).subscribe(() => {
      
    // });
  }

  private handleLoginSuccess(response) {
    this.currentUser = response;

    // Set Current User object in local storage
    this.setUser(response);

    window.localStorage.setItem("token", response.token);

    // Set Current BusinessId in local storage
    this.setBusinessId(this.currentUser.defaultBusinessId);

    window['posthog'].identify(response.email, {
      email: response.email
    });
  

    // Navigate to Dashboard
    this.router.navigate(["dashboard"]);

    return;
  }

  private setUser(user) {
    window.localStorage.setItem(LOCALSTORAGEKEYS.USER, JSON.stringify(user));
  }

  private setBusinessId(businessId: number) {
    window.localStorage.setItem(
      LOCALSTORAGEKEYS.BUSINESSID,
      businessId.toString()
    );
  }
}
