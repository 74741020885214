import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ObligationService {
    private permissionsSubject = new BehaviorSubject<any[]>([]);
    private obligationTypesSubject = new BehaviorSubject<any[]>([]);

    permissions$ = this.permissionsSubject.asObservable();
    obligationTypes$ = this.obligationTypesSubject.asObservable();

    constructor(private http: HttpClient) {
        this.loadAccounts();
    }

    private loadAccounts() {
        this.http.get("api/obligationTypes/permissions").subscribe((response: any[]) => {
            this.permissionsSubject.next(response);
        });

        this.http.get("api/obligationTypes").subscribe((response: any[]) => {
            this.obligationTypesSubject.next(response);
          });
    }
}
