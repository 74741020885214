import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateAccountComponent } from '../create-account/create-account.component';

@Component({
  selector: 'app-template-browser',
  templateUrl: './template-browser.component.html',
  styleUrls: ['./template-browser.component.css']
})
export class TemplateBrowserComponent implements OnInit {

  @Input() templates: any[];
  @Output() accountAdded: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  curentTemplate = 0;

  ngOnInit() {
    this.templates.forEach(x => x.isActive = false);
    this.templates[0].isActive = true;
  }

  select(template) {
    const modalRef = this.modalService.open(CreateAccountComponent, { backdropClass: 'in', windowClass: 'in' });
    modalRef.componentInstance.account = template;
    modalRef.componentInstance.isNewAccount = true;
    modalRef.componentInstance.accountCreated.subscribe((value) => this.accountAdded.emit({account: value, type: template.type}));
    this.activeModal.close('Cross click');
  }

  close() {
    this.activeModal.close('Cross click');
  }

}
