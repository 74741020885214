import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-profile-add",
  templateUrl: "./profile-add.component.html",
  styleUrls: ["./profile-add.component.css"],
})
export class ProfileAddComponent implements OnInit {
  private readonly baseRoute = "api/profiles";

  loading;
  id: number = this.route.snapshot.params["id"];
  addScreen: boolean = this.route.snapshot.data["addScreen"];

  model = {
    fullName: null,
    fullNameUrdu: null,
    email: null,
    phoneNumber: null,
    notes: null,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private toastrService: ToastrService
  ) {
    this.loadProfile();
  }

  ngOnInit() {}

  loadProfile() {
    if (!this.id || this.addScreen) {
      return;
    }

    this.http
      .get(`${this.baseRoute}/${this.id}`)
      .subscribe((res: any) => (this.model = res));
  }

  submitForm(form: NgForm) {
    this.loading = true;
    if (this.addScreen) {
      this.http.post("api/profiles", this.model).subscribe(
        (res: any) => {
          this.toastrService.success("Profile added successfully!", "SUCCESS");
          this.router.navigate(["/profiles/edit", res.id]);
          this.loading = false;
        },
        (error) => {
          this.toastrService.error(error.error.message, "ERROR");
          this.loading = false;
        },
        () => (this.loading = false)
      );
    } else {
      this.http.put(`api/profiles/${this.id}`, this.model).subscribe(
        (res: any) => {
          form.reset(form.value);
          this.toastrService.success(
            "Profile updated successfully!",
            "SUCCESS"
          );
          this.loading = false;
        },
        (error) => {
          this.toastrService.error(error.error.message, "ERROR");
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }
  }
}
