import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-profile-list",
  templateUrl: "./profile-list.component.html",
  styleUrls: ["./profile-list.component.css"],
})
export class ProfileListComponent implements OnInit {
  private readonly baseRoute = "api/profiles";

  loading = false;
  profiles;

  filters = {
    keywords: "",
    sortBy: "id",
    sortOrder: "desc",
    pageNumber: 1,
    pageSize: 10,
  };

  paging = {
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    recordCount: 0,
  };

  constructor(private http: HttpClient) {
    this.loadProfiles();
  }

  ngOnInit() {}

  loadProfiles() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const params = new URLSearchParams();

    for (const key of Object.keys(this.filters)) {
      const value = this.filters[key];
      if (value) {
        params.set(key, this.filters[key]);
      }
    }

    this.http.get(this.baseRoute + "/paged?" + params.toString()).subscribe(
      (res: any) => {
        this.profiles = res.records;

        this.paging.pageNumber = res.pageNumber;
        this.paging.pageSize = res.pageSize;
        this.paging.pageCount = res.pageCount;
        this.paging.recordCount = res.recordCount;
      },
      () => {},
      () => (this.loading = false)
    );
  }

  hasPreviousPage() {
    return this.paging.pageNumber > 1;
  }

  hasNextPage() {
    return this.paging.pageNumber < this.paging.pageCount;
  }
}
