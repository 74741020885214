import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ReceiptService } from "shared/services/receipt.service";

@Component({
  selector: "app-add-deposit",
  templateUrl: "./add-deposit.component.html",
  styleUrls: ["./add-deposit.component.css"],
})
export class AddDepositComponent implements OnInit {
  loading = false;
  type;
  id;
  addScreen;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private receiptService: ReceiptService,
    private toastrService: ToastrService
  ) {
    
  }
  ngOnInit(): void {
    this.addScreen = this.route.snapshot.data["addScreen"];
    this.type = this.route.snapshot.data["type"];
    this.id = this.route.snapshot.params["id"];
    this.model.transactionTypeId = this.type === "deposit" ? 5 : 6;

    if (this.id) {
      this.http
        .get(`api/transactions/${this.id}`)
        .subscribe((res: TransactionModel) => {
          this.model = res
          console.log(res.paymentAccountId);
        });
    }
  }

  model: TransactionModel = {
    id: 0,
    entryNumber: "000000",
    transactionTypeId: null,
    personId: null,
    refPersonId: null,
    dateTime: new Date().toISOString(),
    paymentAccountId: null,
    currencyId: null,
    exchangeRate: 1,
    paymentMethodId: null,
    lines: [{ accountId: null, memo: null, amount: null }],
    memo: null,
  };

  addLine() {
    if (this.model.lines.length === 12) {
      alert("Maximum 12 lines are allowed");

      return;
    }

    this.model.lines.push({
      accountId: null,
      memo: null,
      amount: null,
    });
  }

  removeLine(line, index) {
    if (this.getUnDeletedLinesCount() === 1) {
      alert("At lease one line is required");

      return;
    }

    if (line.id > 0) {
      line.isDeleted = true;
    } else {
      this.model.lines.splice(index, 1);
    }
  }

  undoDeleteLine(line) {
    line.isDeleted = false;
  }

  getTotalAmount() {
    return this.model.lines.reduce((a, b) => a + b.amount, 0);
  }

  createTransaction(print = false, redirectToList = false) {
    this.loading = true;
    this.http.post("api/transactions", this.model).subscribe(
      (respone: TransactionModel) => {
        this.model = respone;
        this.loading = false;
        this.addScreen = false;
        this.id = this.model.id;
        this.toastrService.success(
          "Entry has been created successfully!",
          "Success"
        );

        if (print) {
          this.print();
        }

        if (redirectToList) {
          this.router.navigate(["/accounting/transactions"]);
        }
      },
      () => {
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  updateTransaction(print = false, redirectToList = false) {
    this.loading = true;
    this.http.put("api/transactions", this.model).subscribe(
      (respone: TransactionModel) => {
        this.model = respone;
        this.loading = false;
        this.toastrService.success(
          "Entry has been updated successfully!",
          "Success"
        );

        if (print) {
          this.print();
        }

        if (redirectToList) {
          this.router.navigate(["/accounting/transactions"]);
        }
      },
      () => {
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  save(print = false, redirectToList = false) {
    if (this.addScreen) {
      this.createTransaction(print, redirectToList);
    } else {
      this.updateTransaction(print, redirectToList);
    }
  }

  getUnDeletedLinesCount() {
    return this.model.lines.filter((x) => !x.isDeleted).length;
  }

  delete() {
    if (confirm("Are you sure you want to delete this transaction?")) {
      this.http.delete(`api/transactions/${this.id}`).subscribe((res) => {
        this.router.navigate(["/accounting/transactions"]);
        this.toastrService.success(
          "Entry has been deleted successfully!",
          "Success"
        );
      });
    }
  }

  cancel() {
    this.router.navigate(["accounting/transactions"]);
  }

  print() {
    this.receiptService.printReceipt(
      this.id,
      this.model.transactionTypeId,
      true
    );
  }
}

interface TransactionModel {
  id: number;
  entryNumber: string;
  transactionTypeId: number;
  personId: number;
  refPersonId: number;
  dateTime: string;
  paymentAccountId: number;
  currencyId: number;
  exchangeRate: number;
  paymentMethodId: number;
  lines: any[];
  memo: string;
}
