import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  email = null;
  emailSent = false;
  loading = false;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit() {}

  submitForm(form: NgForm) {
    this.loading = true;
    this.http.post("api/auth/forgot-password", form.value).subscribe(
      (response) => {
        this.emailSent = true;
        this.email = form.value.email;
      },
      (error: HttpErrorResponse) => alert(error.error),
      () => {
        this.loading = false;
        form.reset();
      }
    );
  }
}
