import { ObligationReceiptComponent } from "./shared/components/obligation-receipt/obligation-receipt.component";
import { Routes } from "@angular/router";
import { AuthGuard } from "auth/guards/auth.guard";
import { ReceiptComponent } from "shared/components/receipt/receipt.component";

import { ChartaccountComponent } from "./pages/accounting/chartaccount/chartaccount.component";
import { JournalEntryComponent } from "./pages/accounting/journaltransactions/journal-entry/journal-entry.component";
import { JournaltransactionsComponent } from "./pages/accounting/journaltransactions/journaltransactions.component";
import { AddDepositComponent } from "./pages/accounting/transactions/add-deposit/add-deposit.component";
import { AddObligationComponent } from "./pages/accounting/transactions/add-obligation/add-obligation.component";
import { CurrencyExchangeComponent } from "./pages/accounting/transactions/currency-exchange/currency-exchange.component";
import { FundsTransferComponent } from "./pages/accounting/transactions/funds-transfer/funds-transfer.component";
import { TransactionsComponent } from "./pages/accounting/transactions/transactions.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { MujtahidsAddComponent } from "./pages/obligations/mujtahids/mujtahids-add/mujtahids-add.component";
import { MujtahidsListComponent } from "./pages/obligations/mujtahids/mujtahids-list/mujtahids-list.component";
import { ObligationListComponent } from "./pages/obligations/obligation-list/obligation-list.component";
import { ObligationTypeListComponent } from "./pages/obligations/obligation-types/obligation-type-list/obligation-type-list.component";
import { RepresentativesAddComponent } from "./pages/obligations/representatives/representatives-add/representatives-add.component";
import { RepresentativesListComponent } from "./pages/obligations/representatives/representatives-list/representatives-list.component";
import { ProfileAddComponent } from "./pages/profiles/profile-add/profile-add.component";
import { ProfileListComponent } from "./pages/profiles/profile-list/profile-list.component";
import { AccountTransactionsComponent } from "./pages/reports/account-transactions/account-transactions.component";
import { BalanceSheetComponent } from "./pages/reports/balance-sheet/balance-sheet.component";
import { GeneralLedgerComponent } from "./pages/reports/general-ledger/general-ledger.component";
import { IncomeStatementComponent } from "./pages/reports/income-statement/income-statement.component";
import { ObligationReportComponent } from "./pages/reports/obligation-report/obligation-report.component";
import { TrialBalanceComponent } from "./pages/reports/trial-balance/trial-balance.component";
import { BusinessesComponent } from "./pages/settings/account/businesses/businesses.component";
import { ChangepasswordComponent } from "./pages/settings/account/changepassword/changepassword.component";
import { ProfileComponent } from "./pages/settings/account/profile/profile.component";
import { BusinessAddComponent } from "./pages/settings/advancedsettings/businesses/business-add/business-add.component";
import { BusinessesComponent as AccountBusinessesComponent } from "./pages/settings/advancedsettings/businesses/businesses.component";
import { UserAddComponent } from "./pages/settings/advancedsettings/users/user-add/user-add.component";
import { UsersComponent } from "./pages/settings/advancedsettings/users/users.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { FundsTransferVoucherComponent } from "shared/components/funds-transfer-voucher/funds-transfer-voucher.component";

export const appRoutes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },
      { path: "receipt/:id/:language", component: ReceiptComponent },
      {
        path: "obligation-receipt/:id/:language",
        component: ObligationReceiptComponent,
      },
      {
        path: "funds-transfer-voucher/:id/:language",
        component: FundsTransferVoucherComponent,
      },
      {
        path: "profiles",
        children: [
          { path: "list", component: ProfileListComponent },
          {
            path: "add",
            component: ProfileAddComponent,
            data: { addScreen: true },
          },
          {
            path: "edit/:id",
            component: ProfileAddComponent,
            data: { addScreen: false },
          },
        ],
      },
      {
        path: "accounting",
        children: [
          { path: "", redirectTo: "transactions", pathMatch: "full" },
          {
            path: "transactions",
            children: [
              { path: "", component: TransactionsComponent },
              {
                path: "add-deposit",
                component: AddDepositComponent,
                data: { type: "deposit", addScreen: true },
              },
              {
                path: "deposit/:id",
                component: AddDepositComponent,
                data: { type: "deposit", addScreen: false },
              },
              {
                path: "payment/:id",
                component: AddDepositComponent,
                data: { type: "payment", addScreen: false },
              },
              {
                path: "add-payment",
                component: AddDepositComponent,
                data: { type: "payment", addScreen: true },
              },
              {
                path: "obligation",
                component: AddObligationComponent,
                data: { type: "deposit", addScreen: true },
              },
              {
                path: "obligation/:id",
                component: AddObligationComponent,
                data: { type: "deposit", addScreen: false },
              },
              {
                path: "funds-transfer",
                component: FundsTransferComponent,
                data: { type: "deposit", addScreen: true },
              },
              {
                path: "funds-transfer/:id",
                component: FundsTransferComponent,
                data: { type: "deposit", addScreen: false },
              },
              {
                path: "currency-exchange",
                component: CurrencyExchangeComponent,
                data: { type: "deposit", addScreen: true },
              },
              {
                path: "currency-exchange/:id",
                component: CurrencyExchangeComponent,
                data: { type: "deposit", addScreen: false },
              },
            ],
          },
          {
            path: "journal-transactions",
            children: [
              {
                path: "",
                component: JournaltransactionsComponent,
              },
              {
                path: "add",
                component: JournalEntryComponent,
              },
              {
                path: "edit/:id",
                component: JournalEntryComponent,
              },
            ],
          },
          {
            path: "chart-of-accounts",
            component: ChartaccountComponent,
          },
        ],
      },
      {
        path: "obligations",
        children: [
          { path: "list", component: ObligationListComponent },
          { path: "types", component: ObligationTypeListComponent },
          {
            path: "mujtahids",
            children: [
              { path: "", redirectTo: "list", pathMatch: "full" },
              { path: "list", component: MujtahidsListComponent },
              {
                path: "add",
                component: MujtahidsAddComponent,
                data: { addScreen: true },
              },
              {
                path: "edit/:id",
                component: MujtahidsAddComponent,
                data: { addScreen: false },
              },
            ],
          },
          {
            path: "representatives",
            children: [
              { path: "", redirectTo: "list", pathMatch: "full" },
              { path: "list", component: RepresentativesListComponent },
              {
                path: "add",
                component: RepresentativesAddComponent,
                data: { addScreen: true },
              },
              {
                path: "edit/:id",
                component: RepresentativesAddComponent,
                data: { addScreen: false },
              },
            ],
          },
        ],
      },
      {
        path: "reports",
        children: [
          { path: "balance-sheet", component: BalanceSheetComponent },
          { path: "income-statement", component: IncomeStatementComponent },
          { path: "general-ledger", component: GeneralLedgerComponent },
          {
            path: "account-transactions",
            component: AccountTransactionsComponent,
          },
          { path: "trial-balance", component: TrialBalanceComponent },
          { path: "obligation-report", component: ObligationReportComponent },
        ],
      },
      {
        path: "settings",
        children: [
          {
            path: "",
            redirectTo: "/settings/account/profile/edit",
            pathMatch: "full",
          },
          { path: "account/profile/edit", component: ProfileComponent },
          {
            path: "account/change-password",
            component: ChangepasswordComponent,
          },
          { path: "account/default-business", component: BusinessesComponent },
          {
            path: "advanced-settings",
            children: [
              {
                path: "businesses",
                children: [
                  {
                    path: "",
                    component: AccountBusinessesComponent,
                  },
                  {
                    path: "edit/:id",
                    component: BusinessAddComponent,
                  },
                ],
              },
              {
                path: "users",
                children: [
                  { path: "", component: UsersComponent },
                  {
                    path: "add",
                    component: UserAddComponent,
                    data: { addScreen: true },
                  },
                  { path: "edit/:id", component: UserAddComponent },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  { path: "**", component: NotFoundComponent, pathMatch: "full" },
];
