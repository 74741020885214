import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-user-add",
  templateUrl: "./user-add.component.html",
  styleUrls: ["./user-add.component.css"],
})
export class UserAddComponent implements OnInit {
  user: any = {
    fullName: null,
  };
  id;
  addScreen;
  businesses = null;
  loading = false;

  confirmEmailSent = false;

  constructor(
    private http: HttpClient,
    route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService
  ) {
    this.addScreen = route.snapshot.data["addScreen"];
    this.id = route.snapshot.params["id"];
    this.loadUser();
    this.loadBusinesses();
  }
  ngOnInit() {}

  loadBusinesses() {
    this.http.get("api/businesses").subscribe(
      (response) => (this.businesses = response),
      () => {},
      () => {
        this.setBusinessesIsChecked();
      }
    );
  }

  loadUser() {
    if (!this.id) {
      return;
    }

    this.http.get("api/users/" + this.id).subscribe(
      (response) => (this.user = response),
      () => {},
      () => {
        this.setBusinessesIsChecked();
      }
    );
  }

  updateUser() {
    this.loading = true;

    this.user.businessIds = this.businesses
      .filter((b) => b.isChecked)
      .map((b) => b.id);

    if (this.addScreen) {
      this.http.post("/api/users", this.user).subscribe(
        (response: any) => {
          this.router.navigate([
            "/settings/advanced-settings/users/edit/",
            response.id,
          ]);

          this.toastrService.success("User created successfully!", "SUCCESS");
        },
        (error) => {
          this.toastrService.error(error.error.message, "ERROR");
        },
        () => {
          this.loading = false;
        }
      );
    } else {
      this.http.put("/api/users", this.user).subscribe(
        (response) => {
          this.toastrService.success("User updated successfully!", "SUCCESS");
        },
        (error) => {
          this.toastrService.error(error.error.message, "ERROR");
        },
        () => {
          this.loading = false;
        }
      );
    }
  }

  atLeastOneBusinessSelected() {
    return this.businesses && this.businesses.some((b) => b.isChecked);
  }

  setBusinessesIsChecked() {
    if (this.businesses && this.user.businessIds) {
      for (const business of this.businesses) {
        business.isChecked = this.user.businessIds.some(
          (b) => b === business.id
        );
      }
    }
  }

  resendEmail(email) {
    this.http
      .post("api/auth/resend-confirm-email?email=" + email, {})
      .subscribe(
        () => {
          this.confirmEmailSent = true;
        },
        () => {},
        () => {}
      );
  }
}
