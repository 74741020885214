import { Routes } from "@angular/router";

import { ConfirmEmailComponent } from "./pages/confirm-email/confirm-email.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { LoginComponent } from "./pages/login/login.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";

export const authRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "confirm-email", component: ConfirmEmailComponent },
];
