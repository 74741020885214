import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ReceiptService {
  constructor() { }

  readonly TRANSACTION_TYPES = {
    JOURNALENTRY: 1,
    OBLIGATION: 3,
    DEPOSIT: 5,
    PAYMENT: 6,
    FUNDSTRANSFER: 7,
    CURRENCYEXCHANGE: 8,
  };

  public printReceipt(id, transactionTypeId, viewEnglishReceipt = true) {
    const receiptType = this.getReceiptRoute(transactionTypeId);
    this.popupCenter(
      window.location.origin +
      "/" +
      receiptType +
      "/" +
      id +
      "/" +
      (viewEnglishReceipt ? "en" : "ur"),
      "Deposit Slip",
      "754",
      "535"
    );
  }

  private popupCenter(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft =
      window.screenLeft != undefined ? window.screenLeft : screen.availWidth;
    var dualScreenTop =
      window.screenTop != undefined ? window.screenTop : screen.availHeight;

    var width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
    var height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

    var left = width / 2 - w / 2 + dualScreenLeft;
    var top = height / 2 - h / 2 + dualScreenTop;
    var newWindow = window.open(
      url,
      title,
      "scrollbars=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
    );

    // Puts focus on the newWindow
    if (window.focus) {
      newWindow.focus();
    }
  }

  private getReceiptRoute(transactionTypeId) {
    debugger
    switch (transactionTypeId) {
      case this.TRANSACTION_TYPES.OBLIGATION:
        return "obligation-receipt";
      case this.TRANSACTION_TYPES.FUNDSTRANSFER:
        return "funds-transfer-voucher";
      default:
        return "receipt";
    }
  }
}
