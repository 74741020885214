import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-journaltransactions",
  templateUrl: "./journaltransactions.component.html",
  styleUrls: ["./journaltransactions.component.css"],
})
export class JournaltransactionsComponent implements OnInit {
  loading = false;
  entries;

  filters = {
    pageNumber: 1,
    pageSize: 1000,
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.getJouranlEntries();
  }

  getJouranlEntries() {
    if (this.loading) {
      return;
    }
    const params = this.getFiltersQueryString();
    this.loading = true;

    this.http
      .get("api/transactions/journalentries?" + params.toString())
      .subscribe(
        (response) => {
          this.entries = response;
        },
        () => {},
        () => (this.loading = false)
      );
  }

  private getFiltersQueryString() {
    const params = new URLSearchParams();

    for (const key of Object.keys(this.filters)) {
      const value = this.filters[key];
      if (value) {
        params.set(key, this.filters[key]);
      }
    }

    return params.toString();
  }

  editEntry(entry) {
    this.router.navigate(["accounting/journal-transactions/edit", entry.id]);
  }

  deleteEntry(entry) {
    if (confirm("Are you sure you want to delete this journal entry?")) {
      this.http
        .delete(`api/transactions/journalentries/${entry.id}`)
        .subscribe(() => {
          this.entries = this.entries.filter((x) => x.id !== entry.id);

          this.toastrService.success(
            "Journal Entry has been deleted successfully!",
            "Success"
          );
        });
    }
  }
}
