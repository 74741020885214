import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ReceiptService } from "./../../../../shared/services/receipt.service";

@Component({
  selector: "app-currency-exchange",
  templateUrl: "./currency-exchange.component.html",
  styleUrls: ["./currency-exchange.component.css"],
})
export class CurrencyExchangeComponent {
  private readonly baseRoute = "api/transactions/currency-exchanges";

  loading = false;
  id = this.route.snapshot.params["id"];
  addScreen = this.route.snapshot.data["addScreen"];

  model: any = {
    entryNumber: "000000",
    dateTime: new Date().toISOString(),
    paymentMethodId: null,
    fromPaymentAccountId: null,
    soldCurrencyId: null,
    soldAmount: null,
    toPaymentAccountId: null,
    purchasedCurrencyId: null,
    purchasedAmount: null,
    memo: null,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private receiptService: ReceiptService,
    private toastrService: ToastrService
  ) {
    if (this.id && !this.addScreen) {
      this.getTransaction();
    }
  }

  getTransaction() {
    this.http
      .get(`${this.baseRoute}/${this.id}`)
      .subscribe((res) => (this.model = res));
  }

  createTransaction(print = false, redirectToList = false) {
    this.loading = true;
    this.http.post(this.baseRoute, this.model).subscribe(
      (respone) => {
        this.loading = false;
        this.model = respone;
        this.id = this.model.id;
        this.addScreen = false;
        this.toastrService.success(
          "Entry has been created successfully!",
          "Success"
        );

        if (print) {
          this.print();
        }

        if (redirectToList) {
          this.router.navigate(["/accounting/transactions"]);
        }
      },
      () => {},
      () => (this.loading = false)
    );
  }

  updateTransaction(print = false, redirectToList = false) {
    this.http.put(this.baseRoute, this.model).subscribe(() => {
      this.toastrService.success(
        "Entry has been updated successfully!",
        "Success"
      );

      if (print) {
        this.print();
      }

      if (redirectToList) {
        this.router.navigate(["/accounting/transactions"]);
      }
    });
  }

  save(print = false, redirectToList = false) {
    if (this.addScreen) {
      this.createTransaction(print, redirectToList);
    } else {
      this.updateTransaction(print, redirectToList);
    }
  }

  delete() {
    if (confirm("Are you sure you want to delete this transaction?")) {
      this.http.delete(`${this.baseRoute}/${this.id}`).subscribe((res) => {
        this.router.navigate(["/accounting/transactions"]);
        this.toastrService.success(
          "Entry has been deleted successfully!",
          "Success"
        );
      });
    }
  }

  cancel() {
    this.router.navigate(["accounting/transactions"]);
  }

  print() {
    this.receiptService.printReceipt(this.id, this.model.transactionTypeId);
  }
}
