import { Component, ElementRef } from "@angular/core";

@Component({
  selector: "app-dropdown-toggle",
  templateUrl: "./dropdown-toggle.component.html",
  styleUrls: ["./dropdown-toggle.component.css"],
  // host: {
  //   "(document:click)": "onClick($event)",
  // },
})
export class DropdownToggleComponent {
  open = false;

  // constructor(private _eref: ElementRef) {}

  // onClick(event) {

  //   if (!this.open) {
  //     return;
  //   }

  //   let result =
  //     this._eref.nativeElement.children[0].children[0] !== event.target;

  //   if (result) {
  //     this.open = false;
  //   }
  // }
}
