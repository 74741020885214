import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-obligation-list",
  templateUrl: "./obligation-list.component.html",
  styleUrls: ["./obligation-list.component.css"],
})
export class ObligationListComponent implements OnInit {
  private readonly baseRoute = "api/obligations";

  loading = false;
  currencies;
  paymentAccounts;
  mujtahids;
  representatives;

  filters = {
    mujtahidId: null,
    representativeId: null,
    currencyId: null,
    paymentAccountId: null,
    fromDate: null,
    toDate: null,
    sortBy: "id",
    sortOrder: "desc",
    pageNumber: 1,
    pageSize: 10,
  };
  paging = {
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    recordCount: 0,
  };
  obligations;
  constructor(private http: HttpClient) {
    this.loadList();
    this.loadDropDownsData();
  }

  ngOnInit() {}

  loadDropDownsData() {
    this.http.get("api/currencies").subscribe((response: any[]) => {
      this.currencies = response.map(function (m) {
        const o = { id: m.id, name: `${m.isoCode} - ${m.name}` };
        return o;
      });
    });

    this.http.get("api/mujtahids").subscribe((response: any[]) => {
      this.mujtahids = response;
    });

    this.http.get("api/representatives").subscribe((response: any[]) => {
      this.representatives = response;
    });

    this.http
      .get("api/chartaccounts/payment-accounts")
      .subscribe((response: any[]) => {
        this.paymentAccounts = response.map(function (m) {
          const o = { id: m.value, name: m.name };
          return o;
        });
      });
  }

  applyFilters() {
    this.filters.pageNumber = 1;
    this.loadList();
  }

  loadList() {
    this.loading = true;
    const params = this.getFiltersQueryString();

    this.http.get(this.baseRoute + "?" + params.toString()).subscribe(
      (response: any) => {
        this.obligations = response.records;

        this.paging.pageNumber = response.pageNumber;
        this.paging.pageSize = response.pageSize;
        this.paging.pageCount = response.pageCount;
        this.paging.recordCount = response.recordCount;
      },
      () => {},
      () => (this.loading = false)
    );
  }

  private getFiltersQueryString() {
    const params = new URLSearchParams();

    for (const key of Object.keys(this.filters)) {
      const value = this.filters[key];
      if (value) {
        params.set(key, this.filters[key]);
      }
    }

    return params.toString();
  }

  handlePageSizeChange() {
    this.filters.pageNumber = 1;
    this.loadList();
  }

  hasPreviousPage() {
    return this.paging.pageNumber > 1;
  }

  hasNextPage() {
    return this.paging.pageNumber < this.paging.pageCount;
  }

  moveToPreviousPage() {
    if (this.hasPreviousPage()) {
      this.filters.pageNumber = this.filters.pageNumber - 1;
      this.loadList();
    }
  }

  moveToNextPage() {
    if (this.hasNextPage()) {
      this.filters.pageNumber = this.filters.pageNumber + 1;
      this.loadList();
    }
  }
}
