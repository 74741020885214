import { Component, forwardRef, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-delete-button",
  templateUrl: "./delete-button.component.html",
  styleUrls: ["./delete-button.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeleteButtonComponent),
      multi: true,
    },
  ],
})
export class DeleteButtonComponent implements ControlValueAccessor {
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() undoDelete: EventEmitter<any> = new EventEmitter();

  isDeleted: boolean;
  disabled: boolean;

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.isDeleted = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markDelete() {
    // this.isDeleted = true;
    this.onModelChange(this.isDeleted);
    this.delete.emit(this.isDeleted);
  }

  undo() {
    // this.isDeleted = false;
    this.onModelChange(this.isDeleted);
    this.undoDelete.emit(this.isDeleted);
  }
}
