import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ChartAccountService } from "src/app/services/chart-account.service";

@Component({
  selector: "app-currency-select",
  templateUrl: "./currency-select.component.html",
  styleUrls: ["./currency-select.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencySelectComponent),
      multi: true,
    },
  ],
})
export class CurrencySelectComponent implements ControlValueAccessor, OnInit {
  @Input() showAddOption = false;
  @Input() caption;
  @Input() selectDefault = true;
  @Input() value;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  currencies;
  disabled = false;

  constructor(private chartAccountService: ChartAccountService) {
  }

  onModelChange: Function = () => { };

  onModelTouched: Function = () => { };

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(newValue) {
    this.valueChange.emit(newValue);
    this.onModelChange(newValue);
  }

  ngOnInit() {     
    this.chartAccountService.currencies$.subscribe((currencies) => {
      this.currencies = currencies;
      const defaultCurrencyId = 111; // TODO: Replace hard-coded id
      if (!this.value && this.selectDefault && this.currencies.length > 0)
        this.valueChange.emit(defaultCurrencyId);
    });
  }
}
