import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

import { authRoutes } from "./auth.routes";
import { AppHttpInterceptor } from "./interceptors/app.http.interceptor";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { LoginComponent } from "./pages/login/login.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule.forChild(authRoutes)],
  exports: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    CookieService,
  ],
})
export class AuthModule {}
