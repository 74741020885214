import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mujtahids-list",
  templateUrl: "./mujtahids-list.component.html",
  styleUrls: ["./mujtahids-list.component.css"],
})
export class MujtahidsListComponent implements OnInit {
  loading = false;
  mujtahids;

  constructor(private http: HttpClient) {
    this.loadList();
  }

  ngOnInit() {}

  loadList() {
    this.loading = true;
    this.http.get("api/mujtahids").subscribe(
      (res) => (this.mujtahids = res),
      () => {},
      () => (this.loading = false)
    );
  }
}
