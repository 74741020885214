import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "auth/services/auth.service";

@Component({
  selector: "app-trial-balance",
  templateUrl: "./trial-balance.component.html",
  styleUrls: ["./trial-balance.component.css"],
})
export class TrialBalanceComponent implements OnInit {
  business;
  dateNow = new Date();

  loading = false;
  model = {
    data: null,
    reportDate: null,
    totalDebit: null,
    totalCredit: null,
  };
  dateModel: NgbDateStruct = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate(),
  };
  asOfDate = `${this.dateModel.year}-${this.dateModel.month}-${this.dateModel.day}`;

  constructor(private httpClient: HttpClient, authService: AuthService) {
    this.UpdateReport();
    this.business = authService.getBusiness();
  }

  ngOnInit() {}

  UpdateReport() {
    this.asOfDate = `${this.dateModel.year}-${this.dateModel.month}-${this.dateModel.day}`;
    this.getTrialBalanceData();
  }

  getTrialBalanceData() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.httpClient
      .get(`api/reports/trialbalance?businessId=1&date=${this.asOfDate}`)
      .subscribe(
        (response: any) => {
          this.model = response;
        },
        () => {},
        () => (this.loading = false)
      );
  }
}
