import {
  Component,
  DoCheck,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements OnChanges, ControlValueAccessor {
  @Input() wrapped = true;
  @Input() placeholder = "";
  @Input() value: string = new Date().toISOString();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  dateModel: NgbDateStruct;

  disabled = false;

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
      this.parse(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges() {
    this.parse(this.value);
  }

  parse(value: string) {
    if (!value) {
      return;
    }

    const date = new Date(value);

    this.dateModel = {
      ...this.dateModel,
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  updateValue(value) {
    if (value) {
      const newLocal = new Date();
      newLocal.setFullYear(value.year);
      newLocal.setMonth(value.month - 1);
      newLocal.setDate(value.day);

      this.valueChange.emit(newLocal.toISOString());
      this.onModelChange(newLocal.toISOString());
    } else {
      this.valueChange.emit(null);
      this.onModelChange(null);
    }
  }
}
