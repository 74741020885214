import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-mujtahid-select",
  templateUrl: "./mujtahid-select.component.html",
  styleUrls: ["./mujtahid-select.component.css"],
})
export class MujtahidSelectComponent implements OnInit {
  @Input() showAddOption = false;
  @Input() value;
  @Input() caption;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  mujtahids;

  constructor(private httpClient: HttpClient) {
    this.httpClient.get("api/mujtahids").subscribe((response) => {
      this.mujtahids = response;
    });
  }

  ngOnInit() {}
}
