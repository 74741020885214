import { Router } from "@angular/router";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import headerKeysConstants from "auth/constants/headerKeys.constants";
import localStorageKeysConstants from "auth/constants/localStorageKeys.constants";
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from '../../../environments/environment'; // Adjust the path as necessary

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const businessId = window.localStorage.getItem(
      localStorageKeysConstants.BUSINESSID
    );

    const token = window.localStorage.getItem("token");

    if (token) {
      headersConfig["Authorization"] = `Bearer ${token}`;
    }

    if (businessId) {
      headersConfig[headerKeysConstants.BUSINESSID] = businessId;
    }

    // Clone the request and prepend the base URL
    const request = req.clone({
      url: `${environment.apiBaseUrl}/${req.url}`,
      setHeaders: headersConfig,
    });

    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          // Handle successful response if needed
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // Redirect to login page if unauthorized
            this.router.navigate(["/login"]);
            window.localStorage.clear();
          } else if (err.status === 400) {
            // Handle bad request if needed
          } else {
            // Handle other types of errors if needed
            console.error("An error occurred:", err);
          }
        }
        return of(err);
      })
    );
  }
}
