import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  private readonly baseRoute = "api/users/profile";

  profile = {
    fullName: null,
    fullNameUrdu: null,
    email: null,
    phoneNumber: null,
  };

  constructor(private http: HttpClient, private toastrService: ToastrService) {
    this.loadProfile();
  }

  ngOnInit() {}

  loadProfile() {
    this.http
      .get(this.baseRoute)
      .subscribe((response: any) => (this.profile = response));
  }

  updateProfile(form: NgForm) {
    this.http.put(this.baseRoute, this.profile).subscribe(
      () => {
        form.reset(form.value);
        this.toastrService.success("Profile updated successfully!", "SUCCESS");
      },
      (error) => this.toastrService.error(error.error.message, "ERROR")
    );
  }
}
