import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "auth/services/auth.service";

@Component({
  selector: "app-general-ledger",
  templateUrl: "./general-ledger.component.html",
  styleUrls: ["./general-ledger.component.css"],
})
export class GeneralLedgerComponent implements OnInit {
  business;
  dateNow = new Date();

  loading = false;
  model: any;
  dateModel = {
    accountId: 0,
    account: "",
    fromDate: {
      year: this.dateNow.getFullYear(),
      month: this.dateNow.getMonth() + 1,
      day: 1,
    },
    toDate: {
      year: this.dateNow.getFullYear(),
      month: this.dateNow.getMonth() + 1,
      day: this.dateNow.getDate(),
    },
  };
  fromDate = `${this.dateModel.fromDate.year}-${this.dateModel.fromDate.month}-${this.dateModel.fromDate.day}`;
  toDate = `${this.dateModel.toDate.year}-${this.dateModel.toDate.month}-${this.dateModel.toDate.day}`;

  constructor(private httpClient: HttpClient, authService: AuthService) {
    this.updateReport();
    this.business = authService.getBusiness();
  }

  ngOnInit() {}

  public updateReport() {
    this.fromDate = `${this.dateModel.fromDate.year}-${this.dateModel.fromDate.month}-${this.dateModel.fromDate.day}`;
    this.toDate = `${this.dateModel.toDate.year}-${this.dateModel.toDate.month}-${this.dateModel.toDate.day}`;

    this.getReportData();
  }

  public showDebit(item) {
    return Math.abs(
      item.accountType === "assets"
        ? item.positiveAmountSum
        : item.accountType === "expense"
        ? item.positiveAmountSum
        : item.positiveAmountSum
    );
  }

  public showCredit(item) {
    return Math.abs(
      item.accountType === "assets"
        ? item.negativeAmountSum
        : item.accountType === "expense"
        ? item.negativeAmountSum
        : item.negativeAmountSum
    );
  }

  public showNetMovement(item) {
    return item.accountType === "assets" || item.accountType === "expense"
      ? item.positiveAmountSum - Math.abs(item.negativeAmountSum)
      : Math.abs(item.negativeAmountSum) - Math.abs(item.positiveAmountSum);
  }

  public getTotalDebit() {
    return this.model.reduce(function (prev, cur) {
      return prev + Math.abs(cur.positiveAmountSum);
    }, 0);
  }
  public getTotalCredit() {
    return this.model.reduce(function (prev, cur) {
      return prev + Math.abs(cur.negativeAmountSum);
    }, 0);
  }

  public getReportData() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.httpClient
      .get(
        `api/reports/generalLedger?businessId=1&&fromDate=${this.fromDate}&toDate=${this.toDate}`
      )
      .subscribe(
        (response: any) => {
          this.model = response;
        },
        () => {},
        () => (this.loading = false)
      );
  }
}
