import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ReceiptService } from "shared/services/receipt.service";

@Component({
  selector: "app-funds-transfer",
  templateUrl: "./funds-transfer.component.html",
  styleUrls: ["./funds-transfer.component.css"],
})
export class FundsTransferComponent {
  private readonly baseRoute = "api/transactions/funds-transfers";

  loading = false;
  id;
  addScreen;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private http: HttpClient,
    private receiptService: ReceiptService,
    private toastrService: ToastrService
  ) {
    this.addScreen = route.snapshot.data["addScreen"];
    this.id = route.snapshot.params["id"];

    if (this.id) {
      http
        .get(`${this.baseRoute}/${this.id}`)
        .subscribe((res) => (this.model = res));
    }
  }

  model: any = {
    entryNumber: "000000",
    dateTime: new Date().toISOString(),
    currencyId: null,
    exchangeRate: 1,
    paymentMethodId: null,
    fromPaymentAccountId: null,
    toPaymentAccountId: null,
    amount: null,
    memo: null,
  };

  createTransaction(print = false, redirectToList = false) {
    this.loading = true;
    this.http.post(this.baseRoute, this.model).subscribe(
      (respone) => {
        this.loading = false;
        this.model = respone;
        this.id = this.model.id;
        this.addScreen = false;
        this.toastrService.success(
          "Entry has been created successfully!",
          "Success"
        );
        if (print) {
          this.print();
        }

        if (redirectToList) {
          this.router.navigate(["/accounting/transactions"]);
        }
      },
      () => { },
      () => (this.loading = false)
    );
  }

  updateTransaction(print = false, redirectToList = false) {
    this.loading = true;
    this.http.put(this.baseRoute, this.model).subscribe(
      (respone) => {
        this.loading = false;
        this.toastrService.success(
          "Entry has been updated successfully!",
          "Success"
        );
        if (print) {
          this.print();
        }

        if (redirectToList) {
          this.router.navigate(["/accounting/transactions"]);
        }
      },
      () => { },
      () => (this.loading = false)
    );
  }

  save(print = false, redirectToList = false) {
    if (this.addScreen) {
      this.createTransaction(print, redirectToList);
    } else {
      this.updateTransaction(print, redirectToList);
    }
  }

  delete() {
    if (confirm("Are you sure you want to delete this transaction?")) {
      this.http.delete(`${this.baseRoute}/${this.id}`).subscribe((res) => {
        console.log("deleted", res);
        this.router.navigate(["/accounting/transactions"]);
        this.toastrService.success(
          "Entry has been deleted successfully!",
          "Success"
        );
      });
    }
  }

  cancel() {
    this.router.navigate(["accounting/transactions"]);
  }

  print() {
    this.receiptService.printReceipt(this.id, 7);
  }
}
