import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ChartAccountService {
    private accountsSubject = new BehaviorSubject<any[]>([]);
    private paymentAccountsSubject = new BehaviorSubject<any[]>([]);
    private currenciesSubject = new BehaviorSubject<any[]>([]);
    private paymentMethodsSubject = new BehaviorSubject<any[]>([]);

    accounts$ = this.accountsSubject.asObservable();
    paymentAccounts$ = this.paymentAccountsSubject.asObservable();
    currencies$ = this.currenciesSubject.asObservable();
    paymentMethods$ = this.paymentMethodsSubject.asObservable();

    constructor(private http: HttpClient) {
        this.loadAccounts();
    }

    private loadAccounts() {
        this.http.get("api/chartaccounts").subscribe((response: any[]) => {
            const groupedAccounts = this.groupByChartAccounts(response, "accountType");
            const sortedAccounts = this.sortAccounts(groupedAccounts);
            this.accountsSubject.next(sortedAccounts);
        });

        this.http
            .get("api/chartaccounts/payment-accounts")
            .subscribe((response: any[]) => {
                const accounts = response.map((m) => ({ id: m.value, name: m.name }));
                this.paymentAccountsSubject.next(accounts);
            });

        this.http.get("api/currencies").subscribe((response: any[]) => {
            const currencies = response.map(function (m) {
                const o = { id: m.id, name: `${m.isoCode} - ${m.name}` };
                return o;
            });
            this.currenciesSubject.next(currencies);
        });

        this.http.get("api/transactions/payment-methods").subscribe((response: any[]) => {
            this.paymentMethodsSubject.next(response);
          });
    }

    groupByChartAccounts(ourArray: any[], property: string) {
        return ourArray.reduce((accumulator, object) => {
            const key = object[property];
            if (!accumulator.find((m) => m.groupName === key)) {
                accumulator.push({ groupName: key, accounts: [] });
            }
            accumulator.find((m) => m.groupName === key).accounts.push(object);
            return accumulator;
        }, []);
    }

    sortAccounts(accounts: any[]) {
        return accounts.sort((prev, next) => {
            if (prev.groupName === "Assets") return -1;
            if (prev.groupName === "Liabilities") return 0;
            return 1;
        });
    }
}
