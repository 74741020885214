import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-receipt",
  templateUrl: "./receipt.component.html",
  styleUrls: ["./receipt.component.css"],
})
export class ReceiptComponent implements OnInit {
  id;
  data: any = {};

  constructor(private route: ActivatedRoute, private http: HttpClient) {
    this.id = route.snapshot.params["id"];
  }
  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.http.get("api/transactions/" + this.id + "/receipt").subscribe(
      (res: any) => (this.data = res),
      () => {},
      () => setTimeout(() => window.print(), 200)
    );
  }
}
