import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-submit-button",
  templateUrl: "./submit-button.component.html",
  styleUrls: ["./submit-button.component.css"],
})
export class SubmitButtonComponent implements OnInit {
  @Input() loading = false;
  @Input() disabled = false;
  @Input() text;
  @Output() click: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
