import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ObligationService } from "src/app/services/obligation.service";

@Component({
  selector: "app-obligation-type-select",
  templateUrl: "./obligation-type-select.component.html",
  styleUrls: ["./obligation-type-select.component.css"],
})
export class ObligationTypeSelectComponent implements OnInit {
  @Input() showAddOption = false;
  @Input() value;
  @Input() caption;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  obligationTypes;

  constructor(private obligationService: ObligationService) {
    this.obligationService.obligationTypes$.subscribe((response) => {
      this.obligationTypes = response;
    });
  }

  ngOnInit() {}
}
