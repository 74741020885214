import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ChartAccountService } from "src/app/services/chart-account.service";

@Component({
  selector: "app-payment-method-select",
  templateUrl: "./payment-method-select.component.html",
  styleUrls: ["./payment-method-select.component.css"],
})
export class PaymentMethodSelectComponent implements OnInit {
  @Input() showAddOption = false;
  @Input() value: number; // Ensure this is a number type
  @Input() caption: string;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();

  paymentMethods: any[] = [];

  constructor(private chartAccountService: ChartAccountService) {}

  ngOnInit() {
    this.chartAccountService.paymentMethods$.subscribe((response) => {
      this.paymentMethods = response;

      // Check if the value is not set and if there are payment methods
      if (!this.value && this.paymentMethods.length > 0) {
        this.value = this.paymentMethods[0].id; // Set the value
        this.valueChange.emit(this.value); // Emit the change
      }
    });
  }
}
