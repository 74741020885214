import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-obligation-type-add',
  templateUrl: './obligation-type-add.component.html',
  styleUrls: ['./obligation-type-add.component.css']
})
export class ObligationTypeAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
