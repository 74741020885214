import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-obligation-type-list",
  templateUrl: "./obligation-type-list.component.html",
  styleUrls: ["./obligation-type-list.component.css"],
})
export class ObligationTypeListComponent implements OnInit {
  private readonly baseRoute = "api/obligationTypes";

  loading = false;
  types;
  templates;
  selectedTemplateId: number = null;

  constructor(private http: HttpClient) {
    this.loadTemplates();
    this.loadTypes();
  }

  ngOnInit() {}

  loadTemplates() {
    this.http
      .get(`${this.baseRoute}/templates`)
      .subscribe((res) => (this.templates = res));
  }

  loadTypes() {
    this.loading = true;
    this.http.get(`${this.baseRoute}`).subscribe(
      (res) => (this.types = res),
      () => {},
      () => (this.loading = false)
    );
  }

  add() {
    if (!this.selectedTemplateId) {
      return;
    }
  }
}
