import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthService } from "../../services/auth.service";

@Component({
  templateUrl: "./login.component.html",
})
export class LoginComponent {
  showPasswordResetSuccess = false;
  error = false;
  errorMessage: string;
  pageTitle = "Log In";

  constructor(private authService: AuthService, private router: Router) {
    const currentNavigation = router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extras.state) {
      this.showPasswordResetSuccess =
        currentNavigation.extras.state.showPasswordResetSuccess;
    }

    if (authService.isLoggedIn) {
      this.router.navigate(["/dashboard"]);
    }
  }

  login(loginForm: NgForm) {
    if (loginForm && loginForm.valid) {
      const userName = loginForm.form.value.userName;
      const password = loginForm.form.value.password;

      this.authService.login(
        userName,
        password,
        this.handleLoginFailure.bind(this)
      );
    }
  }

  private handleLoginFailure(response: HttpErrorResponse) {
    this.showPasswordResetSuccess = false;
    this.error = true;
    this.errorMessage = "Invalid username or password.";

    setTimeout(() => (this.error = false), 1500);
  }
}
