import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, OnChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-journal-entry",
  templateUrl: "./journal-entry.component.html",
  styleUrls: ["./journal-entry.component.css"],
})
export class JournalEntryComponent implements OnInit {
  model: any = {
    date: new Date().toISOString(),
    lines: [{}, {}],
    name: "",
  };
  isEdit;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService
  ) {}

  ngOnInit() {
    this.isEdit = this.route.toString().includes("edit");
    const id = this.route.snapshot.params["id"];
    if (id) {
      this.getJournalEntry(id);
    }
  }

  cancel() {
    this.router.navigate(["/accounting", "journal-transactions"]);
  }

  addLine() {
    this.model.lines.push({});
  }

  deleteLine(line) {
    if (this.model.lines.length === 1) {
      return;
    }

    const index = this.model.lines.indexOf(line);

    if (line.id) {
      line.isDeleted = true;
    } else {
      this.model.lines.splice(index, 1);
    }
  }

  removeLine(line, index) {
    if (this.getUnDeletedLinesCount() === 2) {
      alert("At lease two lines are required");

      return;
    }

    if (line.id > 0) {
      line.isDeleted = true;
    } else {
      this.model.lines.splice(index, 1);
    }
  }

  getUnDeletedLinesCount() {
    return this.model.lines.filter((x) => !x.isDeleted).length;
  }

  undoDeleteLine(line) {
    line.isDeleted = false;
  }

  getJournalEntry(id) {
    this.http
      .get(`api/transactions/journalentries/${id}`)
      .subscribe((response) => {
        this.model = response;
      });
  }

  getTotalDebit() {
    return this.model.lines
      .filter((x) => !x.isDeleted)
      .reduce((a, b) => a + (b.debit ? b.debit : 0), 0);
  }

  getTotalCredit() {
    return this.model.lines
      .filter((x) => !x.isDeleted)
      .reduce((a, b) => a + (b.credit ? b.credit : 0), 0);
  }

  createEntry() {
    this.http
      .post(`api/transactions/journalentries`, this.model)
      .subscribe(() => {
        this.router.navigate(["/accounting/journal-transactions"]);

        this.toastService.success(
          "Journal Entry has been created successfully!",
          "Success"
        );
      });
  }

  updateEntry() {
    this.http
      .put(`api/transactions/journalentries/${this.model.id}`, this.model)
      .subscribe(() => {
        this.router.navigate(["/accounting/journal-transactions"]);

        this.toastService.success(
          "Journal Entry has been updated successfully!",
          "Success"
        );
      });
  }

  canSubmit() {
    if (this.getTotalCredit() !== this.getTotalDebit()) {
      return false;
    }

    if (this.model.lines.some((l) => l.credit > 0 && l.debit > 0)) {
      return false;
    }

    return true;
  }
}
