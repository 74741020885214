import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "auth/services/auth.service";

@Component({
  selector: "app-account-transactions",
  templateUrl: "./account-transactions.component.html",
  styleUrls: ["./account-transactions.component.css"],
})
export class AccountTransactionsComponent implements OnInit {
  business;
  accountName;
  runningBalance = undefined;
  dateNow = new Date();

  loading = false;
  model: any = {
    account: { runningBalance: null },
  };
  dateModel = {
    accountId: 0,
    account: "",
    fromDate: {
      year: this.dateNow.getFullYear(),
      month: this.dateNow.getMonth() + 1,
      day: 1,
    },
    toDate: {
      year: this.dateNow.getFullYear(),
      month: this.dateNow.getMonth() + 1,
      day: this.dateNow.getDate(),
    },
  };
  fromDate = `${this.dateModel.fromDate.year}-${this.dateModel.fromDate.month}-${this.dateModel.fromDate.day}`;
  toDate = `${this.dateModel.toDate.year}-${this.dateModel.toDate.month}-${this.dateModel.toDate.day}`;
  accounts = [];

  constructor(private httpClient: HttpClient, authService: AuthService) {
    this.getChartAccounts();
    this.business = authService.getBusiness();
  }

  ngOnInit() {}

  public updateReport() {
    this.accountName = this.dateModel.account;
    this.fromDate = `${this.dateModel.fromDate.year}-${this.dateModel.fromDate.month}-${this.dateModel.fromDate.day}`;
    this.toDate = `${this.dateModel.toDate.year}-${this.dateModel.toDate.month}-${this.dateModel.toDate.day}`;

    this.getReportData(this.dateModel.accountId, this.fromDate, this.toDate);
  }

  public getDate(date) {
    return new Date(date.year, date.month, date.day);
  }

  public getReportData(accountId, fromDate, toDate) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.httpClient
      .get(
        `api/reports/accountTransactions?businessId=1&fromDate=${fromDate}&toDate=${toDate}&accountId=${accountId}`
      )
      .subscribe(
        (response) => {
          this.model = response;
        },
        () => {},
        () => (this.loading = false)
      );
  }

  getChartAccounts() {
    this.httpClient.get("api/chartaccounts").subscribe((response: any[]) => {
      const cashOnHandAccountTemplateId = 1;
      const cashOnHandAccount = response.find(
        (x) => x.templateId === cashOnHandAccountTemplateId
      );

      if (cashOnHandAccount) {
        this.dateModel.accountId = cashOnHandAccount.id;
        this.dateModel.account = cashOnHandAccount.name;
        this.updateReport();
      }

      this.accounts = this.groupByChartAccounts(response, "accountType");
      this.accounts.sort((prev, next) => {
        if (prev.groupName === "Assets") {
          return -1;
        } else if (prev.groupName === "Liabilities") {
          return 0;
        } else {
          return 1;
        }
      });
    });
  }

  groupByChartAccounts(OurArray, property) {
    return OurArray.reduce(function (accumulator, object) {
      // get the value of our object(age in our case) to use for group    the array as the array key
      const key = object[property];
      // if the current value is similar to the key(age) don't accumulate the transformed array and leave it empty
      if (!accumulator.find((m) => m.groupName === key)) {
        accumulator.push({ groupName: key, accounts: [] });
      }
      // add the value to the array
      accumulator.find((m) => m.groupName === key).accounts.push(object);
      // return the transformed array
      return accumulator;
      // Also we also set the initial value of reduce() to an empty object
    }, []);
  }

  getRunningBalance(line, i) {
    if (i === 0) {
      this.model.account.runningBalanceABC = this.model.account.openingBalance;
    }

    if (this.model.account.debitBalance) {
      this.model.account.runningBalanceABC += line.debit - line.credit;
    } else {
      this.model.account.runningBalanceABC += line.credit - line.debit;
    }

    return this.model.account.runningBalanceABC;
  }
}
