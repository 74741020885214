import { Component, OnInit } from '@angular/core';
import { primaryNav} from 'src/assets/js/primaryNav';

@Component({
  selector: '[sidepanel]',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.css']
})
export class SidepanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(function() {
      primaryNav();
    }, 500);
  }
}
