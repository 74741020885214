import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.css"],
})
export class ConfirmEmailComponent implements OnInit {
  email;
  code;
  accountActivated = false;
  loading = true;

  constructor(
    route: ActivatedRoute,
    private http: HttpClient,
    private toastrService: ToastrService,
    private router: Router
  ) {
    route.queryParams.subscribe((response) => {
      this.email = response.email;
      this.code = response.code;
    });
  }

  ngOnInit() {
    if (this.email && this.code) {
      this.http
        .post(
          `api/auth/confirm-email?email=${this.email}&code=${this.code}`,
          null
        )
        .subscribe(
          (response) => {
            this.accountActivated = true;
            this.loading = false;
          },
          (error) => {},
          () => {
            this.loading = false;
          }
        );
    }
  }

  submitForm(form: NgForm) {
    this.loading = true;
    this.http
      .post("api/auth/set-password", {
        email: this.email,
        password: form.value.newPassword,
      })
      .subscribe(
        (res) => {
          this.loading = false;

          this.router.navigate(["/login"]);

          this.toastrService.success(
            "Your password has been created successfully, now you can login!",
            "SUCCESS",
            {
              timeOut: 10000,
            }
          );
        },
        (error) => {
          this.loading = false;
          this.toastrService.error(error.error.message, "ERROR");
        },
        () => {
          this.loading = false;
        }
      );
  }
}
