import { Component, OnInit } from "@angular/core";
import { AuthService } from "auth/services/auth.service";
import { Business } from "user/user";

@Component({
  selector: "app-businessswitcher",
  templateUrl: "./businessswitcher.component.html",
  styleUrls: ["./businessswitcher.component.css"],
})
export class BusinessswitcherComponent implements OnInit {
  user;
  business;
  businesses: Business[];

  constructor(private authService: AuthService) {
    this.user = authService.getUser();
    this.business = authService.getBusiness();
    this.businesses = authService.getAllBusinesses();
  }

  ngOnInit() {}

  switchAccout(accountToSwitch: Business) {
    this.authService.switchBusiness(accountToSwitch.id);
  }

  logOut(): void {
    this.authService.logout();
  }
}
