import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ProfilesService {
    private profilesSubject = new BehaviorSubject<any[]>([]);

    profiles$ = this.profilesSubject.asObservable();

    constructor(private http: HttpClient) {
        this.loadAccounts();
    }

    private loadAccounts() {
        this.http.get("api/profiles").subscribe((response: any[]) => {
            const profiles = response.map((m) => ({ id: m.id, name: m.fullName }));
            this.profilesSubject.next(profiles);
        });
    }
}
