import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from "ng2-charts";

import * as actionTypes from "./../../state/app.actions";
import { getCountSelector } from "./../../state/app.selectors";
import { AppState } from "./../../state/app.state";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return "Rs " + this.numberWithCommas(value);
            }.bind(this),
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  public barChartLabels: Label[];
  public barChartData: ChartDataSets[] = [
    {
      data: [],
      label: "Income",
      backgroundColor: "#3cb6b2",
      hoverBackgroundColor: "#118783",
    },
    {
      data: [],
      label: "Expense",
      backgroundColor: "#bfcfd7",
      hoverBackgroundColor: "#a1becd",
    },
  ];

  // Pie
  public pieChartType: ChartType = "pie";
  public pieChartLegend = true;
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "right",
    },
    tooltips: {
      enabled: true,
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          debugger;
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: "#ffffff",
      },
    },
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartColors = [
    {
      backgroundColor: [
        "#849194",
        "#2CB5E2",
        "#AEE137",
        "#A94FC9",
        "#DCD318",
        "#D74242",
        "#db4e48",
      ],
    },
  ];

  count;

  loadingAccountBalances = true;
  loadingAccountReceivables = true;
  loadingAccountPayables = true;
  loadingProfitAndLoss = true;
  loadingExpenses = true;

  accountReceivables: any[];
  accountPayables: any[];
  accountBalances: any[];
  profitAndLoss;
  expenses;

  constructor(private store: Store<AppState>, private http: HttpClient) {
    store
      .pipe(select(getCountSelector))
      .subscribe((count) => (this.count = count));

    this.loadStatisticsData();
  }

  ngOnInit() {}

  loadStatisticsData() {
    this.http.get("api/reports/accountreceivables").subscribe(
      (res: any[]) => {
        this.accountReceivables = res
        

        if(this.accountReceivables.length > 0){
          this.accountReceivables.push(
            {"fullName": "Total", 
              "amount": this.accountReceivables.reduce((prev, curr) => prev + curr.amount, 0),
            "isTotal": true
          })
        }
      },
      () => {},
      () => (this.loadingAccountReceivables = false)
    );

    this.http.get("api/reports/accountpayables").subscribe(
      (res: any[]) => {
        this.accountPayables = res;
        
        if(this.accountPayables.length > 0){
          this.accountPayables.push(
            {"fullName": "Total", 
              "amount": this.accountPayables.reduce((prev, curr) => prev + curr.amount, 0),
              "isTotal": true
            })
          }
      },
      () => {},
      () => (this.loadingAccountPayables = false)
    );

    this.http.get("api/reports/accountbalances").subscribe(
      (res: any[]) => (this.accountBalances = res),
      () => {},
      () => (this.loadingAccountBalances = false)
    );

    this.http.get("api/reports/profitandloss").subscribe(
      (res: any) => {
        if (res) {
          this.barChartLabels = [...res.categories];
          this.barChartData[0].data = [...res.income];
          this.barChartData[1].data = [...res.expense];
        }
      },
      () => {},
      () => (this.loadingProfitAndLoss = false)
    );

    this.http.get("api/reports/expenses").subscribe(
      (res: any[]) => {
        if (res.length > 0) {
          for (const expense of res) {
            this.pieChartLabels.push(expense.account);
            this.pieChartData.push(expense.sum);
          }
        }
      },
      () => {},
      () => (this.loadingExpenses = false)
    );
  }

  increment() {
    this.store.dispatch(new actionTypes.Increment(this.count + 1));
  }

  decrement() {
    this.store.dispatch(new actionTypes.Decrement(this.count - 1));
  }

  reset() {
    this.store.dispatch(new actionTypes.Reset());
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.random() * 100,
      56,
      Math.random() * 100,
      40,
    ];
    this.barChartData[0].data = data;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
