import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  loadingUsers = false;
  users;

  constructor(private http: HttpClient) {
    this.loadUsers();
  }

  ngOnInit() {}

  loadUsers() {
    this.loadingUsers = true;
    this.http.get("api/users").subscribe(
      (response) => (this.users = response),
      () => {},
      () => (this.loadingUsers = false)
    );
  }
}
