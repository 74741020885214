import { AppState } from "./state/app.state";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "./auth/services/auth.service";
import { Component, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  businessName = "Business Name";

  constructor(private authService: AuthService, public router: Router) {
    window.addEventListener("storage", function (e) {
      router.navigate(["dashboard"]);
      window.location.href = "dashboard";
    });
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  isReceiptPage() {
    return (
      this.router.url.includes("receipt") || this.router.url.includes("voucher")
    );
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }
}
