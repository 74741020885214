import { AppActions, AppActionTypes } from "./app.actions";
import { initialState, AppState } from "./app.state";

export function reducer(state = initialState, action: AppActions): AppState {
  switch (action.type) {
    case AppActionTypes.Increment:
      return { ...state, count: action.payload };

    case AppActionTypes.Decrement:
      return { ...state, count: action.payload };

    case AppActionTypes.Reset:
      return { ...state, count: 0 };

    default:
      return state;
  }
}
