import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NgForm } from "@angular/forms";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.css"],
})
export class ChangepasswordComponent implements OnInit {
  constructor(private http: HttpClient, private toastrService: ToastrService) {}

  ngOnInit() {}

  submitForm(form: NgForm) {
    if (form.valid) {
      if (form.value.NewPassword !== form.value.ConfirmPassword) {
        return;
      }

      this.http.put("api/users/change-password", form.value).subscribe(
        (response) => {
          this.toastrService.success(
            "Password changed successfully!",
            "SUCCESS"
          );
        },
        (error: HttpErrorResponse) =>
          this.toastrService.error(error.error.message, "ERROR"),
        () => form.reset()
      );
    }
  }
}
