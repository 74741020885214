import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "auth/services/auth.service";

@Component({
  selector: "app-balance-sheet",
  templateUrl: "./balance-sheet.component.html",
  styleUrls: ["./balance-sheet.component.css"],
})
export class BalanceSheetComponent implements OnInit {
  business;
  dateNow = new Date();

  loading = false;
  model = {
    asOf: null,
    data: null,
    accountTypes: null,
    currentYearEarnings: 0,
    totalLiabilitiesAndEquity: 0,
  };
  dateModel: NgbDateStruct = {
    year: this.dateNow.getFullYear(),
    month: this.dateNow.getMonth() + 1,
    day: this.dateNow.getDate(),
  };
  asOfDate = `${this.dateModel.year}-${this.dateModel.month}-${this.dateModel.day}`;

  constructor(
    private httpClient: HttpClient,
    authService: AuthService,
    private toastrService: ToastrService
  ) {
    this.UpdateReport();
    this.business = authService.getBusiness();
  }

  ngOnInit() {}

  UpdateReport() {
    this.asOfDate = `${this.dateModel.year}-${this.dateModel.month}-${this.dateModel.day}`;
    this.getBalanceSheetData();
  }

  getBalanceSheetData() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.httpClient
      .get(`api/reports/balancesheet?businessId=1&date=${this.asOfDate}`)
      .subscribe(
        (response: any) => {
          this.model = response;
          this.dateModel.year = 2022;
        },
        (error) => {
          this.toastrService.error(error.error.message, "ERROR");
          this.loading = false;
        },
        () => (this.loading = false)
      );
  }
}
