import { Action } from "@ngrx/store";

export enum AppActionTypes {
  Increment = "[App] Increment",
  Decrement = "[App] Decrement",
  Reset = "[App] Reset",
}

export class Increment implements Action {
  readonly type = AppActionTypes.Increment;

  constructor(public payload: number) {}
}

export class Decrement implements Action {
  readonly type = AppActionTypes.Decrement;

  constructor(public payload: number) {}
}

export class Reset implements Action {
  readonly type = AppActionTypes.Reset;
}

export type AppActions = Increment | Decrement | Reset;
