import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-business-add",
  templateUrl: "./business-add.component.html",
  styleUrls: ["./business-add.component.css"],
})
export class BusinessAddComponent implements OnInit {
  id;
  model;
  constructor(
    route: ActivatedRoute,
    private http: HttpClient,
    private toastrService: ToastrService
  ) {
    this.id = route.snapshot.params["id"];

    if (this.id) {
      http
        .get(`api/businesses/${this.id}`)
        .subscribe((res) => (this.model = res));
    }
  }

  ngOnInit() {}

  submit(form: NgForm) {
    this.http.put(`api/businesses/${this.id}`, this.model).subscribe(
      (response) => {
        form.reset(form.value);
        this.toastrService.success("Business updated successfully!", "SUCCESS");
      },
      (error) => {
        this.toastrService.error(error.error.message, "ERROR");
      }
    );
  }
}
