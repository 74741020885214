import { ToastrService } from "ngx-toastr";
import { AuthService } from "auth/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-businesses",
  templateUrl: "./businesses.component.html",
  styleUrls: ["./businesses.component.css"],
})
export class BusinessesComponent implements OnInit {
  private readonly baseRoute = "api/businesses";

  loadingBusinesses = false;
  defaultBusinessId;
  businesses;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {
    this.loadAllBusinesses();
    this.defaultBusinessId = authService.getUser().defaultBusinessId;
  }

  ngOnInit() {}

  loadAllBusinesses() {
    this.loadingBusinesses = true;
    this.http.get(this.baseRoute).subscribe(
      (response) => (this.businesses = response),
      () => {},
      () => (this.loadingBusinesses = false)
    );
  }

  changePrimaryBusiness(business) {
    if (business.id === this.defaultBusinessId) {
      return;
    }

    const result = confirm(
      `Do you really want to make "${business.name}" as your Primary Business?`
    );

    if (result) {
      this.http
        .put("api/users/change-primary-business/" + business.id, {})
        .subscribe(() => {
          this.authService.resetDefaultBusinessId(business.id);
          this.defaultBusinessId = this.authService.getUser().defaultBusinessId;

          this.toastrService.success(
            `${business.name} has been set as your primary business successfully!`,
            "SUCCESS"
          );
        });
    }
  }
}
