import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthModule } from "auth/auth.module";

import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AccountSelectComponent } from "./controls/account-select/account-select.component";
import { ChoosenSelectComponent } from "./controls/choosen-select/choosen-select.component";
import { CurrencySelectComponent } from "./controls/currency-select/currency-select.component";
import { DatePickerComponent } from "./controls/date-picker/date-picker.component";
import { DeleteButtonComponent } from "./controls/delete-button/delete-button.component";
import { DropdownToggleComponent } from "./controls/dropdown-toggle/dropdown-toggle.component";
import { MujtahidSelectComponent } from "./controls/mujtahid-select/mujtahid-select.component";
import { ObligationTypeSelectComponent } from "./controls/obligation-type-select/obligation-type-select.component";
import { PaymentAccountsSelectComponent } from "./controls/payment-accounts-select/payment-accounts-select.component";
import { PaymentMethodSelectComponent } from "./controls/payment-method-select/payment-method-select.component";
import { PersonSelectComponent } from "./controls/person-select/person-select.component";
import { RepresentativeSelectComponent } from "./controls/representative-select/representative-select.component";
import { SelectComponent } from "./controls/select/select.component";
import { SubmitButtonComponent } from "./controls/submit-button/submit-button.component";
import { HeaderComponent } from "./layout/header/header.component";
import { NavMenuComponent } from "./layout/nav-menu/nav-menu.component";
import { BusinessswitcherComponent } from "./layout/sidepanel/businessswitcher/businessswitcher.component";
import { FooterComponent } from "./layout/sidepanel/footer/footer.component";
import { NavigationComponent } from "./layout/sidepanel/navigation/navigation.component";
import { SidepanelComponent } from "./layout/sidepanel/sidepanel.component";
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { FillPipe } from "./pipes/fill.pipe";
import { NumberWithCommasPipe } from "./pipes/number-with-commas.pipe";
import { PluralPipe } from "./pipes/plural.pipe";
import { RoundPipe } from "./pipes/round.pipe";
import { TimingPipe } from "./pipes/timing.pipe";
import { ReceiptComponent } from "./components/receipt/receipt.component";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { UrduDatePipe } from "./pipes/urdu-date.pipe";
import { SwitchComponent } from "./controls/switch/switch.component";
import { FundsTransferVoucherComponent } from "./components/funds-transfer-voucher/funds-transfer-voucher.component";

@NgModule({
  declarations: [
    NavigationComponent,
    FooterComponent,
    SidepanelComponent,
    BusinessswitcherComponent,
    NavMenuComponent,
    HeaderComponent,
    CurrencySelectComponent,
    PaymentAccountsSelectComponent,
    PaymentMethodSelectComponent,
    PersonSelectComponent,
    DropdownToggleComponent,
    ChoosenSelectComponent,
    AccountSelectComponent,
    DatePickerComponent,
    NotFoundComponent,
    FillPipe,
    MujtahidSelectComponent,
    RepresentativeSelectComponent,
    ObligationTypeSelectComponent,
    SelectComponent,
    CapitalizePipe,
    NumberWithCommasPipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    DeleteButtonComponent,
    SubmitButtonComponent,
    ReceiptComponent,
    UrduDatePipe,
    SwitchComponent,
    FundsTransferVoucherComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AuthModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ToastrModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NavigationComponent,
    FooterComponent,
    SidepanelComponent,
    BusinessswitcherComponent,
    NavMenuComponent,
    HeaderComponent,
    CurrencySelectComponent,
    PaymentAccountsSelectComponent,
    PaymentMethodSelectComponent,
    PersonSelectComponent,
    DropdownToggleComponent,
    ChoosenSelectComponent,
    AccountSelectComponent,
    DatePickerComponent,
    NotFoundComponent,
    FillPipe,
    MujtahidSelectComponent,
    RepresentativeSelectComponent,
    ObligationTypeSelectComponent,
    SelectComponent,
    CapitalizePipe,
    NumberWithCommasPipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    DeleteButtonComponent,
    SubmitButtonComponent,
    ReceiptComponent,
    FundsTransferVoucherComponent,
    ToastrModule,
    UrduDatePipe,
    SwitchComponent,
  ],
})
export class SharedModule {}
