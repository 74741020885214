import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "urduDate",
})
export class UrduDatePipe implements PipeTransform {
  transform(value: any): string {
    return this.convertToUrduDate(value);
  }

  convertToUrduDate(theDate): string {
    let fullDate = new Date(theDate);

    let monthInUrdu = "";
    let month = fullDate.getMonth() + 1;

    switch (month) {
      case 1:
        monthInUrdu = "جنوری";
        break;
      case 2:
        monthInUrdu = "فروری";
        break;
      case 3:
        monthInUrdu = "مارچ";
        break;
      case 4:
        monthInUrdu = "اپریل";
        break;
      case 5:
        monthInUrdu = "مئی";
        break;
      case 6:
        monthInUrdu = "جون";
        break;
      case 7:
        monthInUrdu = "جولائی";
        break;
      case 8:
        monthInUrdu = "اگست";
        break;
      case 9:
        monthInUrdu = "ستمبر";
        break;
      case 10:
        monthInUrdu = "اکتوبر";
        break;
      case 11:
        monthInUrdu = "نومبر";
        break;
      default:
        monthInUrdu = "دسمبر";
    }

    return (
      "" +
      this.pad(fullDate.getDate().toString()) +
      " " +
      monthInUrdu +
      "، " +
      fullDate.getFullYear().toString() +
      ""
    );
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
}
