import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "auth/services/auth.service"; import { SelectItem } from 'primeng/api';

@Component({
  selector: "app-obligation-report",
  templateUrl: "./obligation-report.component.html",
  styleUrls: ["./obligation-report.component.css"],
})
export class ObligationReportComponent implements OnInit {
  business;
  dateNow = new Date();
  model: any;
  mujtahids;
  representatives;
  obligationTypes: SelectItem[];

  filter: any = {
    fromDate: null,
    toDate: null,
    mujtahidId: null,
    representativeId: null,
    typeId: []
  };

  loading = false;
  dateModel = {
    accountId: 0,
    account: "",
    fromDate: {
      year: this.dateNow.getFullYear(),
      month: this.dateNow.getMonth(),
      day: 1,
    },
    toDate: {
      year: this.dateNow.getFullYear(),
      month: this.dateNow.getMonth(),
      day: this.dateNow.getDate(),
    },
  };
  fromDate = `${this.dateModel.fromDate.year}-${this.dateModel.fromDate.month}-${this.dateModel.fromDate.day}`;
  toDate = `${this.dateModel.toDate.year}-${this.dateModel.toDate.month}-${this.dateModel.toDate.day}`;
  reportMeta;

  constructor(
    private httpClient: HttpClient,
    authService: AuthService,
    private toastrService: ToastrService
  ) {
    this.updateReport();
    this.business = authService.getBusiness();
  }

  ngOnInit() {
    this.loadHeading();
    this.loadMujtahids();
    this.loadRepresentatives();
    this.loadObligationTypes();
  }

  public updateReport() {
    this.fromDate = `${this.dateModel.fromDate.year}-${this.dateModel.fromDate.month}-${this.dateModel.fromDate.day}`;
    this.toDate = `${this.dateModel.toDate.year}-${this.dateModel.toDate.month}-${this.dateModel.toDate.day}`;

    this.getReportData();
  }

  getTotalAmountReceived(summary) {
    return summary.reduce(function (prev, cur) {
      return prev + cur.amountReceived;
    }, 0);
  }

  getTotalAmountPayable(summary) {
    return summary.reduce(function (prev, cur) {
      return prev + cur.amountPayable;
    }, 0);
  }

  private getFiltersQueryString() {
    const params = new URLSearchParams();

    for (const key of Object.keys(this.filter)) {
      const value = this.filter[key];
      if (value) {
        if (Array.isArray(value)) {
          for (let v of value) {
            params.append(key, v);

          }
        } else {
          params.append(key, this.filter[key]);
        }
      }
    }

    return params.toString();
  }

  getReportData() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const params = this.getFiltersQueryString();
    this.httpClient
      .get("api/reports/obligations?" + params.toString())
      .subscribe(
        (response) => {
          this.model = response;
        },
        () => {
          this.loading = false;
          this.toastrService.error("Something went wrong", "Error");
        },
        () => (this.loading = false)
      );
  }

  loadMujtahids() {
    this.httpClient
      .get("api/mujtahids")
      .subscribe((response) => (this.mujtahids = response));
  }
  loadRepresentatives() {
    this.httpClient
      .get("api/representatives")
      .subscribe((response) => (this.representatives = response));
  }
  loadObligationTypes() {
    this.httpClient
      .get("api/ObligationTypes")
      .subscribe((response: any[]) => (this.obligationTypes = response.map(x => ({ label: x.name, value: x.id }))));
  }

  loadHeading() {
    this.httpClient
      .get("api/reports/obligations/headings")
      .subscribe((response) => (this.reportMeta = response));
  }
}
