import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ChartAccountService } from "src/app/services/chart-account.service";

@Component({
  selector: "app-payment-accounts-select",
  templateUrl: "./payment-accounts-select.component.html",
  styleUrls: ["./payment-accounts-select.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentAccountsSelectComponent),
      multi: true,
    },
  ],
})
export class PaymentAccountsSelectComponent implements ControlValueAccessor, OnInit {
  @Input() showAddOption = false;
  @Input() caption;
  @Input() selectDefault = true;
  @Input() value: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();

  disabled = false;
  accounts;

  constructor(private chartAccountService: ChartAccountService) {}

  ngOnInit(): void {
    this.chartAccountService.paymentAccounts$.subscribe((response) => {
      this.accounts = response;

      // Check if the value is not set and if there are payment methods
      if (!this.value && this.selectDefault && this.accounts.length > 0) {
        this.value = this.accounts[0].id; // Set the value
        this.valueChange.emit(this.value); // Emit the change
      }
    });
  }

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(newValue) {
    this.valueChange.emit(newValue);
    this.onModelChange(newValue);
  }
}
