import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-obligation-receipt",
  templateUrl: "./obligation-receipt.component.html",
  styleUrls: ["./obligation-receipt.component.css"],
})
export class ObligationReceiptComponent implements OnInit {
  id;
  lang;
  data: any = {};

  constructor(private route: ActivatedRoute, private http: HttpClient) {
    this.id = route.snapshot.params["id"];
    this.lang = route.snapshot.params["language"];
  }
  ngOnInit() {
    this.loadData(this.lang);
  }

  loadData(lang) {
    this.http
      .get(
        "api/transactions/" +
          this.id +
          "/receipt" +
          "?english=" +
          (lang === "en")
      )
      .subscribe(
        (res: any) => (this.data = res),
        () => {},
        () =>
          setTimeout(() => {
            window.print();
          }, 200)
      );
  }

  toggleLang() {
    this.lang = this.lang === "ur" ? "en" : "ur";
    this.loadData(this.lang);
  }
}
